import { APCalculatorBase } from '../../../components/tools/APCalculatorBase';

const frqQuestions = [
  {
    name: "Long Free Response 1",
    maxScore: 10,
    weight: 0.125,
    description: "Cellular processes and molecular biology"
  },
  {
    name: "Long Free Response 2",
    maxScore: 10,
    weight: 0.125,
    description: "Evolution and ecology"
  },
  {
    name: "Short Free Response 1",
    maxScore: 4,
    weight: 0.0625,
    description: "Genetics and inheritance"
  },
  {
    name: "Short Free Response 2",
    maxScore: 4,
    weight: 0.0625,
    description: "Biological systems and organisms"
  },
  {
    name: "Short Free Response 3",
    maxScore: 4,
    weight: 0.0625,
    description: "Data analysis and experimental design"
  }
];

const apBioConfig = {
  title: "AP Biology Score Calculator 2024 | AP Bio Score Calculator",
  description: "Calculate your AP Biology exam score instantly with our detailed calculator. Updated for 2024 AP Bio exam format with comprehensive scoring guidelines for both multiple choice and free response sections.",
  keywords: [
    "ap bio score calculator",
    "AP Bio calculator",
    "ap bio exam score calculator",
    "AP Biology score calculator",
    "AP Bio score estimation",
    "AP Biology exam calculator",
    "2024 AP Biology scoring",
    "AP Bio score conversion",
    "AP Biology FRQ scoring"
  ],
  mcqTotal: 60,
  frqQuestions,
  mcqWeight: 0.5,
  frqWeight: 0.5,
  path: "/tools/ap-bio-score-calculator"
};

export function APBioCalculator() {
  return <APCalculatorBase config={apBioConfig} />;
}