const DIFFICULTY_WEIGHTS = {
    easy: 1,
    medium: 1.25,
    hard: 1.5
  };
  
  export function calculateSectionScore(modules) {
    const totalPossiblePoints = modules.reduce((sum, module) => {
      return sum + (module.totalQuestions * DIFFICULTY_WEIGHTS[module.difficulty]);
    }, 0);
  
    const earnedPoints = modules.reduce((sum, module) => {
      return sum + (module.correctAnswers * DIFFICULTY_WEIGHTS[module.difficulty]);
    }, 0);
  
    const percentageCorrect = earnedPoints / totalPossiblePoints;
    
    // Convert to scaled score (200-800 range)
    const scaledScore = Math.round(600 * percentageCorrect + 200);
    
    // Ensure score is within valid range
    return Math.min(800, Math.max(200, scaledScore));
  }
  