import { motion } from 'framer-motion';

export function ScoringGuide() {
  return (
    <motion.section 
      className="bg-white p-8 rounded-lg shadow-lg"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <h2 className="text-2xl font-bold text-gray-900 mb-6">Digital SAT Scoring Guide</h2>
      <div className="grid md:grid-cols-2 gap-8">
        <div>
          <h3 className="text-lg font-semibold text-blue-600 mb-4">Math Section (200-800)</h3>
          <ul className="space-y-3 text-gray-600">
            <li className="flex items-center">
              <span className="w-24 font-medium">700-800:</span>
              <span>Advanced Mathematics Skills</span>
            </li>
            <li className="flex items-center">
              <span className="w-24 font-medium">600-700:</span>
              <span>Strong Problem-Solving</span>
            </li>
            <li className="flex items-center">
              <span className="w-24 font-medium">500-600:</span>
              <span>Good Foundation</span>
            </li>
            <li className="flex items-center">
              <span className="w-24 font-medium">200-500:</span>
              <span>Needs Improvement</span>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-semibold text-blue-600 mb-4">Reading & Writing (200-800)</h3>
          <ul className="space-y-3 text-gray-600">
            <li className="flex items-center">
              <span className="w-24 font-medium">700-800:</span>
              <span>Advanced Comprehension</span>
            </li>
            <li className="flex items-center">
              <span className="w-24 font-medium">600-700:</span>
              <span>Strong Analysis Skills</span>
            </li>
            <li className="flex items-center">
              <span className="w-24 font-medium">500-600:</span>
              <span>Good Understanding</span>
            </li>
            <li className="flex items-center">
              <span className="w-24 font-medium">200-500:</span>
              <span>Needs Practice</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="mt-8 p-4 bg-blue-50 rounded-md">
        <h3 className="text-lg font-semibold text-blue-800 mb-2">Understanding Your Score</h3>
        <p className="text-gray-700">
          The Digital SAT uses adaptive testing to provide more accurate scores. Your performance in the first module 
          determines the difficulty of your second module. This calculator takes into account both module difficulty 
          and correct answers to estimate your final score.
        </p>
      </div>
    </motion.section>
  );
}