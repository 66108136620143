import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Footer from '../../components/Footer';

const tools = [
    {
      title: 'SAT Score Calculator',
      description: 'Calculate your Digital SAT score with our adaptive scoring system',
      path: '/sat-score-calculator',
      icon: '📊'
    },
    {
      title: 'ACT Score Calculator',
      description: 'Convert your raw ACT scores to scaled scores and composite score',
      path: '/act-score-calculator',
      icon: '📈'
    },
    {
      title: 'AP US History Calculator',
      description: 'Calculate your AP US History score',
      path: '/apush-score-calculator',
      icon: '🗽'
    },
    {
      title: 'AP Language Calculator',
      description: 'Calculate your AP English Language score',
      path: '/ap-lang-score-calculator',
      icon: '📝'
    },
    {
      title: 'AP Biology Calculator',
      description: 'Calculate your AP Biology exam score',
      path: '/ap-bio-score-calculator',
      icon: '🧬'
    },
    {
      title: 'AP Chemistry Calculator',
      description: 'Calculate your AP Chemistry exam score',
      path: '/ap-chem-score-calculator',
      icon: '⚗️'
    },
    {
      title: 'AP Literature Calculator',
      description: 'Calculate your AP English Literature score',
      path: '/ap-lit-score-calculator',
      icon: '📚'
    },
    {
      title: 'AP Government Calculator',
      description: 'Calculate your AP Government exam score',
      path: '/ap-gov-score-calculator',
      icon: '⚖️'
    },
    {
      title: 'AP Psychology Calculator',
      description: 'Calculate your AP Psychology exam score',
      path: '/ap-psych-score-calculator',
      icon: '🧠'
    },
    {
      title: 'AP Calculus AB Calculator',
      description: 'Calculate your AP Calculus AB score',
      path: '/ap-calc-ab-score-calculator',
      icon: '📐'
    },
    {
      title: 'AP Calculus BC Calculator',
      description: 'Calculate your AP Calculus BC score',
      path: '/ap-calc-bc-score-calculator',
      icon: '📏'
    },
    {
      title: 'AP World History Calculator',
      description: 'Calculate your AP World History exam score',
      path: '/ap-world-score-calculator',
      icon: '🌍'
    },
    {
      title: 'AP Physics Calculator',
      description: 'Calculate your AP Physics exam score',
      path: '/ap-physics-score-calculator',
      icon: '⚡'
    },
    {
      title: 'AP Computer Science A Calculator',
      description: 'Calculate your AP CSA exam score',
      path: 'ap-csa-score-calculator',
      icon: '💻'
    },
    {
      title: 'AP European History Calculator',
      description: 'Calculate your AP European History exam score',
      path: '/ap-euro-score-calculator',
      icon: '🏰'
    },
    {
      title: 'AP Macroeconomics Calculator',
      description: 'Calculate your AP Macroeconomics exam score',
      path: '/ap-macro-score-calculator',
      icon: '📊'
    },
    {
      title: 'AP Physics 1 Calculator',
      description: 'Calculate your AP Physics 1 exam score',
      path: '/ap-physics-1-score-calculator',
      icon: '⚛️'
    },
    {
      title: 'AP Physics C Calculator',
      description: 'Calculate your AP Physics C exam score',
      path: '/ap-physics-c-score-calculator',
      icon: '🔬'
    },
    {
      title: 'AP Computer Science Principles Calculator',
      description: 'Calculate your AP CSP exam score',
      path: '/ap-csp-score-calculator',
      icon: '🖥️'
    }
  ];

export function Tools () {
  return (
    <div className="max-w-6xl mx-auto">
      <motion.h1
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-4xl font-bold text-center mb-12 text-gray-800"
      >
        Educational Score Calculators
      </motion.h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {tools.map((tool, index) => (
          <motion.div
            key={tool.path}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <Link
              to={`/tools${tool.path}`}
              className="block h-full bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow p-6"
            >
              <div className="text-4xl mb-4">{tool.icon}</div>
              <h2 className="text-xl font-semibold text-gray-800 mb-2">
                {tool.title}
              </h2>
              <p className="text-gray-600">
                {tool.description}
              </p>
            </Link>
          </motion.div>
        ))}
      </div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.4 }}
        className="mt-12 text-center text-gray-600"
      >
        <p>Choose a calculator to get started with your score estimation.</p>
        <p className="mt-2 text-sm">All calculators are updated for 2024 formats.</p>
      </motion.div>
      <Footer />
    </div>
    
  );
}
