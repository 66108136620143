import { motion } from 'framer-motion';

export function InfoSection() {
  return (
    <motion.section
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white p-6 rounded-lg shadow-lg mb-8"
    >
      <h2 className="text-2xl font-bold text-gray-800 mb-4">About the Digital SAT</h2>
      
      <div className="grid md:grid-cols-2 gap-6">
        <div>
          <h3 className="text-lg font-semibold text-blue-600 mb-2">Test Format</h3>
          <ul className="space-y-2 text-gray-600">
            <li>• 2 hours and 14 minutes total</li>
            <li>• Adaptive testing technology</li>
            <li>• Math and Reading & Writing sections</li>
            <li>• Scores range from 400-1600</li>
          </ul>
        </div>
        
        <div>
          <h3 className="text-lg font-semibold text-blue-600 mb-2">Key Features</h3>
          <ul className="space-y-2 text-gray-600">
            <li>• Shorter, more focused sections</li>
            <li>• Digital-only format</li>
            <li>• Built-in calculator for Math</li>
            <li>• Faster score reporting</li>
          </ul>
        </div>
      </div>

      <div className="mt-6 p-4 bg-blue-50 rounded-md">
        <h3 className="text-lg font-semibold text-blue-800 mb-2">How to Use This Calculator</h3>
        <ol className="list-decimal list-inside space-y-2 text-gray-700">
          <li>Enter your correct answers for each module</li>
          <li>Select the difficulty level you received</li>
          <li>View your scaled scores per section</li>
          <li>Explore matching colleges based on your total score</li>
        </ol>
      </div>
    </motion.section>
  );
}