import { motion } from 'framer-motion';

export function DetailedFreeResponse({ questions, scores, onScoreChange }) {
  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      className="bg-white p-6 rounded-lg shadow-md"
    >
      <h2 className="text-xl font-semibold mb-4">Free Response Section</h2>
      <div className="space-y-6">
        {questions.map((question, index) => (
          <div key={index} className="border-b pb-4 last:border-b-0">
            <label className="block text-sm text-gray-600 mb-2">
              <span className="font-medium">{question.name}</span>
              <span className="text-sm text-gray-500 ml-2">
                (0-{question.maxScore} points)
              </span>
              {question.description && (
                <span className="block text-xs text-gray-500 mt-1">
                  {question.description}
                </span>
              )}
            </label>
            <div className="flex gap-4 items-center">
              <input
                type="range"
                min="0"
                max={question.maxScore}
                value={scores[index]}
                onChange={(e) => onScoreChange(index, parseInt(e.target.value))}
                className="flex-1"
              />
              <input
                type="number"
                min="0"
                max={question.maxScore}
                value={scores[index]}
                onChange={(e) => onScoreChange(index, parseInt(e.target.value) || 0)}
                className="w-20 px-3 py-1 border rounded-md text-center"
              />
            </div>
            <div className="mt-2 text-xs text-gray-500">
              Weight: {(question.weight * 100).toFixed(1)}% of total score
            </div>
          </div>
        ))}
      </div>
    </motion.div>
  );
}
