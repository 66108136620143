import { APCalculatorBase } from '../../../components/tools/APCalculatorBase';


const frqQuestions = [
  {
    name: "Long Free Response 1",
    maxScore: 15,
    weight: 0.125,
    description: "Mechanics problem solving"
  },
  {
    name: "Long Free Response 2",
    maxScore: 15,
    weight: 0.125,
    description: "Electricity and magnetism"
  },
  {
    name: "Short Free Response 1",
    maxScore: 5,
    weight: 0.125,
    description: "Experimental design"
  },
  {
    name: "Short Free Response 2",
    maxScore: 5,
    weight: 0.125,
    description: "Data analysis"
  }
];

const apPhysicsCConfig = {
  title: "AP Physics C Score Calculator",
  description: "Calculate your AP Physics C exam score instantly with our detailed calculator. Updated for 2024 AP Physics C exam format.",
  keywords: [
    "AP Physics C score calculator",
    "AP Physics C calculator",
    "AP Physics C score estimation",
    "2024 AP Physics C scoring",
    "AP Physics mechanics calculator"
  ],
  mcqTotal: 35,
  frqQuestions,
  mcqWeight: 0.5,
  frqWeight: 0.5,
  path: "/tools/ap-physics-c-score-calculator"
};

export function APPhysicsCCalculator() {
  return <APCalculatorBase config={apPhysicsCConfig} />;
}