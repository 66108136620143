import { APCalculatorBase } from '../../../components/tools/APCalculatorBase';

const frqQuestions = [
  {
    name: "Experimental Design",
    maxScore: 12,
    weight: 0.125,
    description: "Design and analyze physics experiments"
  },
  {
    name: "Quantitative/Qualitative Translation",
    maxScore: 12,
    weight: 0.125,
    description: "Convert between mathematical and verbal descriptions"
  },
  {
    name: "Short Answer: Paragraph Argument",
    maxScore: 7,
    weight: 0.125,
    description: "Construct scientific arguments using physics principles"
  },
  {
    name: "Short Answer: Data Analysis",
    maxScore: 7,
    weight: 0.125,
    description: "Analyze and interpret experimental data"
  }
];

const apPhysicsConfig = {
  title: "AP Physics Score Calculator 2024 | AP Physics score calculator",
  description: "Calculate your AP Physics exam score instantly with our comprehensive calculator. Updated for 2024 AP Physics exam format with detailed scoring guidelines for multiple choice and free response sections.",
  keywords: [
    "AP Physics score calculator",
    "AP Physics calculator",
    "ap physics 1 score calculator",
    "ap physics c score calculator",
    "AP Physics score estimation",
    "AP Physics exam calculator",
    "2024 AP Physics scoring",
    "AP Physics score conversion",
    "AP Physics FRQ scoring"
  ],
  mcqTotal: 50,
  frqQuestions,
  mcqWeight: 0.5,
  frqWeight: 0.5,
  path: "/tools/ap-physics-score-calculator"
};

export function APPhysicsCalculator() {
  return <APCalculatorBase config={apPhysicsConfig} />;
}