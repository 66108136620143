import { motion } from 'framer-motion';

export function ACTFAQ() {
  return (
    <motion.section 
      className="bg-white p-8 rounded-lg shadow-lg mt-8"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <h2 className="text-2xl font-bold text-gray-900 mb-6">Frequently Asked Questions</h2>
      <div className="space-y-6">
        <div>
          <h3 className="text-lg font-semibold text-gray-800 mb-2">How is the ACT scored?</h3>
          <p className="text-gray-600">
            The ACT consists of four main sections: English, Math, Reading, and Science. Each section is scored on a scale of 1-36. Your composite score is the average of these four scores, rounded to the nearest whole number. The optional Writing test is scored separately on a scale of 2-12.
          </p>
        </div>
        <div>
          <h3 className="text-lg font-semibold text-gray-800 mb-2">What's a good ACT score?</h3>
          <p className="text-gray-600">
            The average ACT score is around 21. Scores of 24+ put you in the 74th percentile or higher. Highly selective colleges typically look for scores of 30+, which puts you in the 93rd percentile or higher. However, what constitutes a "good" score depends on your target schools.
          </p>
        </div>
        <div>
          <h3 className="text-lg font-semibold text-gray-800 mb-2">How accurate is this calculator?</h3>
          <p className="text-gray-600">
            This calculator provides a close approximation based on typical ACT scoring patterns. However, actual scores may vary slightly due to the test's specific equating process and scaling adjustments made for each test administration.
          </p>
        </div>
        <div>
          <h3 className="text-lg font-semibold text-gray-800 mb-2">How can I improve my ACT score?</h3>
          <p className="text-gray-600">
            To improve your ACT score: practice regularly with official ACT materials, focus on understanding the types of questions in each section, improve your time management, review content areas where you struggle, and take full-length practice tests under timed conditions.
          </p>
        </div>
        <div>
          <h3 className="text-lg font-semibold text-gray-800 mb-2">Should I take the ACT or SAT?</h3>
          <p className="text-gray-600">
            Consider taking practice tests for both exams to see which format suits you better. The ACT includes a Science section and tends to be more straightforward but time-pressured, while the SAT has more context-dependent questions and allows more time per question.
          </p>
        </div>
      </div>
    </motion.section>
  );
}