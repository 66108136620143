import React, { Suspense, lazy, useState, useEffect, createContext } from 'react';
import { Outlet } from 'react-router-dom';
import { GlobalStyle } from './styles/StyledComponents';
import Navigation from './components/Navigation';

export const LocationContext = createContext();

// const HomePage = lazy(() => import('./pages/HomePage'));
// const ExamPage = lazy(() => import('./pages/ExamPage'));
// const MockTestPage = lazy(() => import('./pages/MockTestPage'));
// const ResultsPage = lazy(() => import('./pages/ResultsPage'));
// const LoginPage = lazy(() => import('./pages/LoginPage'));
// const SignupPage = lazy(() => import('./pages/SignupPage'));
// const DataUploadPage = lazy(() => import('./pages/DataUploadPage'));
// // const ProfilePage = lazy(() => import('./pages/ProfilePage'));

function App() {
  const [userLocation, setUserLocation] = useState('US');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const detectLocation = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        setUserLocation(data.country_code);
      } catch (error) {
        console.log('Using default location: US');
      } finally {
        setIsLoading(false);
      }
    };
    detectLocation();
  }, []);

  useEffect(() => {
    const locationBasedMeta = {
      'US': {
        title: 'US Test Prep - SAT, ACT, AP Exam Practice | MockTestPro',
        description: 'Prepare for SAT, ACT, AP exams with comprehensive study materials and practice tests. Get ready for US college admissions.',
        keywords: 'SAT prep, ACT practice, AP exams, US college admissions'
      },
      'IN': {
        title: 'India Exam Prep - SSC, IBPS, UPSC Practice | MockTestPro',
        description: 'Comprehensive preparation for SSC, IBPS, UPSC and other government exams in India. Practice tests and study materials.',
        keywords: 'SSC preparation, IBPS practice, UPSC exam prep'
      }
    };

    const meta = locationBasedMeta[userLocation] || locationBasedMeta['US'];
    document.title = meta.title;
    document.querySelector('meta[name="description"]').content = meta.description;
    document.querySelector('meta[name="keywords"]').content = meta.keywords;
  }, [userLocation]);
  
  return (
    <LocationContext.Provider value={{userLocation, isLoading}}>
      <GlobalStyle />
      <Navigation />
      <Suspense fallback={<div>Loading...</div>}>
        <Outlet />
      </Suspense>
      </LocationContext.Provider>
  );
}

export default App;
