import { APCalculatorBase } from '../../../components/tools/APCalculatorBase';

const frqQuestions = [
  {
    name: "Document-Based Question (DBQ)",
    maxScore: 7,
    weight: 0.25,
    description: "Analysis of historical documents and argument development"
  },
  {
    name: "Long Essay Question",
    maxScore: 6,
    weight: 0.15,
    description: "Historical argument development with evidence"
  },
  {
    name: "Short Answer Question 1",
    maxScore: 3,
    weight: 0.067,
    description: "Historical developments from 1491-1980"
  },
  {
    name: "Short Answer Question 2",
    maxScore: 3,
    weight: 0.067,
    description: "Secondary source analysis"
  },
  {
    name: "Short Answer Question 3",
    maxScore: 3,
    weight: 0.067,
    description: "Historical arguments and evidence"
  }
];

const apushConfig = {
  title: "AP US History Score Calculator 2024 | apush score calculator",
  description: "Calculate your AP US History (APUSH) exam score instantly with our comprehensive calculator. Updated for 2024 APUSH exam format with detailed scoring guidelines for multiple choice and free response sections.",
  keywords: [
    "apush score calculator",
    "APUSH calculator",
    "AP US History score calculator",
    "APUSH score estimation",
    "ap united states history score calculator",
    "2024 APUSH scoring",
    "APUSH score conversion",
    "AP US History FRQ scoring"
  ],
  mcqTotal: 55,
  frqQuestions,
  mcqWeight: 0.4,
  frqWeight: 0.6,
  path: "/tools/apush-score-calculator"
};

export function APUSHCalculator() {
  return <APCalculatorBase config={apushConfig} />;
}