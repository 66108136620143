import { APCalculatorBase } from '../../../components/tools/APCalculatorBase';

const frqQuestions = [
  {
    name: "Create Performance Task",
    maxScore: 6,
    weight: 0.3,
    description: "Program design and implementation"
  }
];

const apCSPConfig = {
  title: "AP Computer Science Score Calculator | AP CSP score calculator",
  description: "Calculate your AP Computer Science Principles exam score instantly with our comprehensive calculator. Updated for 2024 AP CSP exam format.",
  keywords: [
    "AP CSP score calculator",
    "AP CSP calculator",
    "AP Computer Science Principles score calculator",
    "AP CSP score estimation",
    "2024 AP CSP scoring",
    "AP CS Principles calculator"
  ],
  mcqTotal: 70,
  frqQuestions,
  mcqWeight: 0.7,
  frqWeight: 0.3,
  path: "/tools/ap-csp-score-calculator"
};

export function APCSPCalculator() {
  return <APCalculatorBase config={apCSPConfig} />;
}