import { APCalculatorBase } from '../../../components/tools/APCalculatorBase';

const frqQuestions= [
  {
    name: "Poetry Analysis Essay",
    maxScore: 6,
    weight: 0.183,
    description: "Analysis of a poem's meaning and literary techniques"
  },
  {
    name: "Prose Fiction Analysis",
    maxScore: 6,
    weight: 0.183,
    description: "Analysis of a prose passage's literary elements"
  },
  {
    name: "Literary Argument Essay",
    maxScore: 6,
    weight: 0.183,
    description: "Analyze how a theme is developed in a work of literature"
  }
];

const apLitConfig = {
  title: "AP Literature Score Calculator 2024 | AP Lit Score Calculator",
  description: "Calculate your AP English Literature and Composition exam score instantly with our comprehensive calculator. Updated for 2024 AP Lit exam format with detailed scoring guidelines for multiple choice and essay sections.",
  keywords: [
    "ap lit score calculator",
    "AP Lit calculator",
    "AP Literature score calculator",
    "AP Lit score estimation",
    "AP English Literature calculator",
    "2024 AP Literature scoring",
    "AP Lit score conversion",
    "AP Literature essay scoring"
  ],
  mcqTotal: 45,
  frqQuestions,
  mcqWeight: 0.45,
  frqWeight: 0.55,
  path: "/tools/ap-lit-score-calculator"
};

export function APLitCalculator() {
  return <APCalculatorBase config={apLitConfig} />;
}