import { APCalculatorBase } from '../../../components/tools/APCalculatorBase';

const frqQuestions = [
  {
    name: "Long Free Response 1",
    maxScore: 10,
    weight: 0.125,
    description: "Chemical reactions and stoichiometry"
  },
  {
    name: "Long Free Response 2",
    maxScore: 10,
    weight: 0.125,
    description: "Thermodynamics and equilibrium"
  },
  {
    name: "Short Free Response 1",
    maxScore: 4,
    weight: 0.0625,
    description: "Atomic structure and periodicity"
  },
  {
    name: "Short Free Response 2",
    maxScore: 4,
    weight: 0.0625,
    description: "Kinetics and reaction mechanisms"
  },
  {
    name: "Short Free Response 3",
    maxScore: 4,
    weight: 0.0625,
    description: "Solutions and acid-base chemistry"
  }
];

const apChemConfig = {
  title: "AP Chemistry Score Calculator 2024 | AP Chem Score Calculator",
  description: "Calculate your AP Chemistry exam score instantly with our comprehensive calculator. Updated for 2024 AP Chem exam format with detailed scoring guidelines for multiple choice and free response sections.",
  keywords: [
    "ap chem score calculator",
    "AP Chem calculator",
    "AP Chemistry score calculator",
    "AP Chem score estimation",
    "AP Chemistry exam calculator",
    "2024 AP Chemistry scoring",
    "AP Chem score conversion",
    "AP Chemistry FRQ scoring"
  ],
  mcqTotal: 60,
  frqQuestions,
  mcqWeight: 0.5,
  frqWeight: 0.5,
  path: "/tools/ap-chem-score-calculator"
};

export function APChemCalculator() {
  return <APCalculatorBase config={apChemConfig} />;
}