import { MATH_CONVERSION, READING_WRITING_CONVERSION } from './scoringTables';

export const calculateSectionScore = (section, modules) => {
  const conversionTable = section.name === 'Math' ? MATH_CONVERSION : READING_WRITING_CONVERSION;
  const module1Score = modules[0].correctAnswers;
  const module2Score = modules[1].correctAnswers;
  const totalCorrect = module1Score + module2Score;

  let scoringPath;
  if (module1Score >= conversionTable.MODULE1_THRESHOLDS.HIGH) {
    scoringPath = 'HARD_PATH';
  } else if (module1Score >= conversionTable.MODULE1_THRESHOLDS.MEDIUM) {
    scoringPath = 'MEDIUM_PATH';
  } else {
    scoringPath = 'EASY_PATH';
  }

  return conversionTable.SCORE_TABLES[scoringPath][totalCorrect] || 200;
};


