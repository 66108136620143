import { motion } from 'framer-motion';


export function APExamFAQ({ examName }) {
  return (
    <motion.section 
      className="bg-white p-8 rounded-lg shadow-lg mt-8"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <h2 className="text-2xl font-bold text-gray-900 mb-6">Frequently Asked Questions</h2>
      <div className="space-y-6">
        <div>
          <h3 className="text-lg font-semibold text-gray-800 mb-2">How accurate is this {examName} score calculator?</h3>
          <p className="text-gray-600">
            Our calculator uses College Board's official scoring guidelines and rubrics to provide highly accurate estimates. However, actual scores may vary slightly due to exam-specific curves and scoring adjustments made by the College Board.
          </p>
        </div>
        <div>
          <h3 className="text-lg font-semibold text-gray-800 mb-2">How is the {examName} exam scored?</h3>
          <p className="text-gray-600">
            The {examName} exam score is calculated using a weighted combination of multiple-choice questions and free-response questions. The final score is then converted to the AP 5-point scale, where 5 indicates "extremely well qualified" and 1 indicates "no recommendation."
          </p>
        </div>
        <div>
          <h3 className="text-lg font-semibold text-gray-800 mb-2">What score do I need to get college credit?</h3>
          <p className="text-gray-600">
            Most colleges accept scores of 3 or higher for credit, though some prestigious institutions may require a 4 or 5. Each college sets its own AP credit policies, so it's recommended to check with specific institutions about their requirements.
          </p>
        </div>
        <div>
          <h3 className="text-lg font-semibold text-gray-800 mb-2">How should I prepare for the {examName} exam?</h3>
          <p className="text-gray-600">
            Success on the {examName} exam requires a comprehensive understanding of the course material, regular practice with both multiple-choice and free-response questions, and familiarity with the exam format. Using practice tests, review materials, and this calculator can help you track your progress and identify areas for improvement.
          </p>
        </div>
        <div>
          <h3 className="text-lg font-semibold text-gray-800 mb-2">When are AP exam scores released?</h3>
          <p className="text-gray-600">
            AP exam scores are typically released in early July. Students can access their scores through their College Board account. Some students may receive their scores later depending on various factors, including late testing or administrative delays.
          </p>
        </div>
      </div>
    </motion.section>
  );
}