import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getBlogPostBySlug } from '../../services/api';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

const FeaturedImage = styled.img`
  width: 100%;
  max-width: 800px;
  height: auto;
  object-fit: cover;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  display: block;
`;

const ArticleContainer = styled.article`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
`;

const Header = styled.header`
  text-align: center;
  margin-bottom: 32px;
`;

const BlogPost = () => {
  const [post, setPost] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    const fetchPost = async () => {
      const response = await getBlogPostBySlug(slug);
      setPost(response.data.data[0]);
    };
    fetchPost();
  }, [slug]);

  if (!post) return <div>Loading...</div>;

  const renderBlock = (block) => {
    switch (block.__component) {
      case 'shared.rich-text':
        return <ReactMarkdown>{block.body}</ReactMarkdown>;
      case 'shared.media':
        return block.file && (
          <FeaturedImage 
            src={block.file.url} 
            alt={block.file.alternativeText || ''} 
            loading="lazy"
          />
        );
      case 'shared.quote':
        return (
          <blockquote>
            <h3>{block.title}</h3>
            <p>{block.body}</p>
          </blockquote>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Helmet>
        <title>{post.seo?.metaTitle || post.title}</title>
        <meta name="description" content={post.seo?.metaDescription || post.excerpt} />
        <meta name="keywords" content={post.seo?.keywords} />
        {post.seo?.canonicalURL && <link rel="canonical" href={post.seo.canonicalURL} />}
        {post.seo?.metaRobots && <meta name="robots" content={post.seo.metaRobots} />}
        
        {/* Open Graph tags */}
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.excerpt} />
        {post.baseImageFields?.url && <meta property="og:image" content={post.baseImageFields.url} />}
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.title} />
        <meta name="twitter:description" content={post.excerpt} />
      </Helmet>

      <ArticleContainer>
        <Header>
          <h1>{post.title}</h1>
          {post.baseImageFields && (
            <FeaturedImage 
              src={post.baseImageFields.url} 
              alt={post.baseImageFields.altText || post.title}
              loading="eager"
            />
          )}
          <div>
            {post.author && (
              <div>
                {post.author.avatar && (
                  <img 
                    src={post.author.avatar.url} 
                    alt={post.author.name} 
                    style={{ width: 50, height: 50, borderRadius: '50%' }}
                  />
                )}
                <span>By {post.author?.name || 'Anonymous'}</span>
              </div>
            )}
            {/* <time>{new Date(post.publicationDate).toLocaleDateString()}</time> */}
            <span>{post.readingTime} min read</span>
          </div>
        </Header>
        
        <div>
          {post.blocks && post.blocks.map((block) => (
            <div key={block.id}>
              {renderBlock(block)}
            </div>
          ))}
        </div>
      </ArticleContainer>
    </>
  );
};

export default BlogPost;
