import React, { useState, useEffect } from 'react';
import { getBlogPosts } from '../../services/api';
import { Link } from 'react-router-dom';
import '../../BlogList.css';

const BlogList = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const response = await getBlogPosts();
      setPosts(response.data.data);
    };
    fetchPosts();
  }, []);

  return (
    <div className="blog-container">
      <h1 className="blog-main-title">Latest Articles</h1>
      <div className="blog-grid">
        {posts.map(post => (
          <Link to={`/blog/${post.slug}`} className="blog-card" key={post.id}>
            {post.cover && (
              <div className="blog-image-container">
                <img 
                  src={post.cover.url} 
                  alt={post.cover.alternativeText || post.title}
                  className="blog-image"
                />
              </div>
            )}
            <div className="blog-content">
              <h2 className="blog-title">{post.title}</h2>
              <p className="blog-excerpt">{post.excerpt}</p>
              <div className="blog-meta">
                {post.author?.name && (
                  <span className="blog-author">
                    <i className="fas fa-user"></i> {post.author.name}
                  </span>
                )}
                <span className="blog-date">
                  <i className="far fa-calendar"></i> {new Date(post.publicationDate).toLocaleDateString()}
                </span>
                <span className="blog-reading-time">
                  <i className="far fa-clock"></i> {post.readingTime} min read
                </span>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BlogList;