import { useState, useEffect } from 'react';
import { SectionScoring } from '../../components/tools/SectionScoring';
import { InfoSection } from '../../components/tools/InfoSection';
import { calculateSectionScore } from '../../utils/scoreCalculator';
import { motion } from 'framer-motion';
import { FAQ } from '../../components/tools/FAQ';
import { ScoringGuide } from '../../components/tools/ScoringGuide';
import { SEOHead } from '../../components/tools/SEOHead';
import Footer from '../../components/Footer';

export function SATCalculator() {
  const [sections, setSections] = useState([
    {
      name: 'Math',
      modules: [
        { moduleNumber: 1, correctAnswers: 15, totalQuestions: 22, difficulty: 'medium' },
        { moduleNumber: 2, correctAnswers: 12, totalQuestions: 20, difficulty: 'medium' }
      ],
      scaledScore: 200
    },
    {
      name: 'Reading & Writing',
      modules: [
        { moduleNumber: 1, correctAnswers: 18, totalQuestions: 27, difficulty: 'medium' },
        { moduleNumber: 2, correctAnswers: 16, totalQuestions: 27, difficulty: 'medium' }
      ],
      scaledScore: 200
    }
  ]);

  useEffect(() => {
    setSections(prevSections => 
      prevSections.map(section => ({
        ...section,
        scaledScore: calculateSectionScore(section.modules)
      }))
    );
  }, []);

  const updateModuleScore = (sectionIndex, moduleIndex, updatedModule) => {
    setSections(prevSections => {
      const newSections = [...prevSections];
      newSections[sectionIndex].modules[moduleIndex] = updatedModule;
      newSections[sectionIndex].scaledScore = calculateSectionScore(newSections[sectionIndex].modules);
      return newSections;
    });
  };

  const totalScore = sections.reduce((sum, section) => sum + section.scaledScore, 0);

  return (
    <>
    <SEOHead
      title="Digital SAT Score Calculator 2024 | Free Score Estimator"
      description="Calculate your Digital SAT score instantly with our adaptive scoring calculator. Updated for 2024 Digital SAT format with section-wise scoring for Math and Reading & Writing."
      keywords={[
        "SAT score calculator",
        "Digital SAT score calculator",
        "SAT digital score calculator",
        "2024 SAT scoring",
        "SAT Math calculator",
        "SAT Reading Writing calculator",
        "Digital SAT score estimation",
        "Free SAT calculator"
      ]}
      path="/tools/sat-score-calculator"
    />
    <div className="max-w-5xl mx-auto">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center mb-8"
      >
        <h1 className="text-3xl font-bold text-gray-800 mb-2">Digital SAT Score Calculator</h1>
        <p className="text-gray-600">Calculate your Digital SAT scores instantly</p>
      </motion.div>

      <InfoSection />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {sections.map((section, sectionIndex) => (
          <SectionScoring
            key={sectionIndex}
            section={section}
            onModuleChange={(moduleIndex, updatedModule) => 
              updateModuleScore(sectionIndex, moduleIndex, updatedModule)
            }
          />
        ))}
      </div>

      <motion.section 
        className="card p-8 bg-gradient-to-r from-blue-600 to-indigo-600 text-white my-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <h2 className="text-3xl font-bold mb-6">Total Score</h2>
        <div className="flex items-center justify-center">
          <div className="text-7xl font-bold animate-float">
            {totalScore}
          </div>
        </div>
        <p className="text-center mt-4 text-blue-100">
          Based on adaptive scoring across all modules
        </p>
      </motion.section>

      <ScoringGuide />
      <FAQ />
      <Footer />
    </div>
    </>
  );
}
