import { APCalculatorBase } from '../../../components/tools/APCalculatorBase';


const frqQuestions = [
  {
    name: "Document-Based Question (DBQ)",
    maxScore: 7,
    weight: 0.25,
    description: "Analysis of historical documents and argument development"
  },
  {
    name: "Long Essay Question",
    maxScore: 6,
    weight: 0.15,
    description: "Historical argument with evidence"
  },
  {
    name: "Short Answer Question 1",
    maxScore: 3,
    weight: 0.1,
    description: "Historical developments (1450-2001)"
  },
  {
    name: "Short Answer Question 2",
    maxScore: 3,
    weight: 0.1,
    description: "Primary source analysis"
  }
];

const apEuroConfig = {
  title: "AP European History Score Calculator | AP Euro score calculator",
  description: "Calculate your AP European History exam score instantly with our detailed calculator. Updated for 2024 AP Euro exam format.",
  keywords: [
    "AP Euro score calculator",
    "AP Euro calculator",
    "AP European History score calculator",
    "AP Euro score estimation",
    "2024 AP Euro scoring",
    "AP European History exam calculator"
  ],
  mcqTotal: 55,
  frqQuestions,
  mcqWeight: 0.4,
  frqWeight: 0.6,
  path: "/tools/ap-euro-score-calculator"
};

export function APEuroCalculator() {
  return <APCalculatorBase config={apEuroConfig} />;
}