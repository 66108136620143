import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Users, Trophy, Brain, BookOpen, Calculator, FileText } from 'lucide-react';
import { ChevronRight } from 'lucide-react';
import { Helmet } from 'react-helmet';

import Footer from '../../components/Footer';
import { getExamCategoriesForArticles } from '../../services/api';

const iconMapping = {
  'calculator': Calculator,
  'brain': Brain,
  'trophy': Trophy,
  'users': Users,
  'book': BookOpen,
  'file': FileText
};

export default function ExamPage() {
  const { examId } = useParams();
  const [categories, setCategories] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {
      setIsLoading(true);
      try {
        const response = await getExamCategoriesForArticles(examId);
        setCategories(response.data);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCategories();
  }, [examId]);

  if (isLoading) {
    return (
      <div className="bg-gray-50 min-h-screen py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="animate-pulse">
            <div className="text-center mb-12">
              <div className="h-10 w-64 bg-gray-200 rounded-lg mx-auto mb-4"></div>
              <div className="h-6 w-96 bg-gray-200 rounded-lg mx-auto"></div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[1, 2, 3, 4, 5, 6].map((index) => (
                <div key={index} className="bg-white rounded-xl shadow-md overflow-hidden">
                  <div className="h-24 bg-gray-200"></div>
                  <div className="p-6">
                    <div className="h-6 w-3/4 bg-gray-200 rounded mb-4"></div>
                    <div className="h-4 w-full bg-gray-200 rounded mb-4"></div>
                    <div className="h-4 w-2/3 bg-gray-200 rounded"></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
    <Helmet>
      <title>{`${examId.toUpperCase()} Preparation | MockTestPro`}</title>
      <meta name="description" content={`Complete study materials and practice tests for ${examId.toUpperCase()} exam preparation. Choose from our comprehensive topic categories.`} />
      <meta name="keywords" content={`${examId} preparation, ${examId} study material, ${examId} practice tests`} />
      <link rel="canonical" href={`https://mocktestpro.com/articles/${examId}`} />
    </Helmet>

    <div className="bg-gray-50 min-h-screen py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center space-x-2 text-gray-600 mb-8">
          <span className="font-medium">{examId?.toUpperCase()}</span>
        </div>
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Select a Category
          </h1>
          <p className="text-xl text-gray-600">
            Choose a category to start your preparation
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {categories.map((category) => {
            const Icon = iconMapping[category.icon] || BookOpen;
            return (
              <Link
                key={category.documentId}
                to={`${category.slug}`}
                state={{ categoryDetails: category }}
                className="transform hover:scale-105 transition-transform duration-200"
              >
                <div className="bg-white rounded-xl shadow-md overflow-hidden">
                  <div className={`${category.color || 'bg-blue-500'} p-6`}>
                    <Icon className="h-12 w-12 text-white" />
                  </div>
                  <div className="p-6">
                    <h3 className="text-xl font-semibold text-gray-900 mb-2">
                      {category.name}
                    </h3>
                    <p className="text-gray-600 mb-4">{category.description}</p>
                    <div className="flex items-center space-x-4 text-sm text-gray-500">
                      {/* <div className="flex items-center">
                        <FileText className="h-4 w-4 mr-1" />
                        {category.articleCount || 0} Articles
                      </div> */}
                      {/* <div className="flex items-center">
                        <BookOpen className="h-4 w-4 mr-1" />
                        {(category.categories?.length ?? 0)} Topics
                      </div> */}
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
    </>
  );
}
