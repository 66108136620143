import { APCalculatorBase } from '../../../components/tools/APCalculatorBase';

const frqQuestions = [
  {
    name: "Free Response 1",
    maxScore: 9,
    weight: 0.125,
    description: "Differential calculus and applications"
  },
  {
    name: "Free Response 2",
    maxScore: 9,
    weight: 0.125,
    description: "Integral calculus and applications"
  },
  {
    name: "Free Response 3",
    maxScore: 9,
    weight: 0.125,
    description: "Rate of change and accumulation"
  },
  {
    name: "Free Response 4",
    maxScore: 9,
    weight: 0.125,
    description: "Mathematical modeling and analysis"
  }
];

const apCalcABConfig = {
  title: "AP Calculus AB Score Calculator 2024 | AP Calc AB Score Calculator",
  description: "Calculate your AP Calculus AB exam score instantly with our free calculator. Updated for 2024 AP Calc AB exam format with accurate scoring guidelines for both multiple choice and free response sections.",
  keywords: [
    "ap calc ab score calculator",
    "AP Calc AB calculator",
    "AP Calculus AB score calculator",
    "ap calculus score calculator",
    "ap calc calculator",
    "AP Calc AB score estimation",
    "AP Calculus AB exam calculator",
    "2024 AP Calculus AB scoring",
    "AP Calc AB score conversion",
    "AP Calculus AB FRQ scoring"
  ],
  mcqTotal: 45,
  frqQuestions,
  mcqWeight: 0.5,
  frqWeight: 0.5,
  path: "/tools/ap-calc-ab-score-calculator"
};

export function APCalcABCalculator() {
  return <APCalculatorBase config={apCalcABConfig} />;
}