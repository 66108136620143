import { motion } from 'framer-motion';

export function FAQ() {
  return (
    <motion.section 
      className="bg-white p-8 rounded-lg shadow-lg"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <h2 className="text-2xl font-bold text-gray-900 mb-6">Frequently Asked Questions</h2>
      <div className="space-y-6">
        <div>
          <h3 className="text-lg font-semibold text-gray-800 mb-2">How accurate is the Digital SAT Score Calculator?</h3>
          <p className="text-gray-600">
            Our calculator uses College Board's official scoring guidelines and adaptive testing model to provide highly accurate estimates. However, actual scores may vary slightly due to equating and other factors.
          </p>
        </div>
        <div>
          <h3 className="text-lg font-semibold text-gray-800 mb-2">How does the Digital SAT differ from the paper SAT?</h3>
          <p className="text-gray-600">
            The Digital SAT features adaptive testing, shorter sections, and faster score reporting. It maintains the same scoring scale (400-1600) but uses a different format for question delivery and scoring.
          </p>
        </div>
        <div>
          <h3 className="text-lg font-semibold text-gray-800 mb-2">What is adaptive testing in the Digital SAT?</h3>
          <p className="text-gray-600">
            Adaptive testing adjusts question difficulty based on your performance in the first module. Your performance determines whether you receive an easier or harder second module.
          </p>
        </div>
        <div>
          <h3 className="text-lg font-semibold text-gray-800 mb-2">How should I prepare for the Digital SAT?</h3>
          <p className="text-gray-600">
            Practice with digital materials, familiarize yourself with the adaptive format, and use tools like this calculator to understand scoring patterns. Focus on both content knowledge and digital test-taking strategies.
          </p>
        </div>
      </div>
    </motion.section>
  );
}