import { APCalculatorBase } from '../../../components/tools/APCalculatorBase';

const frqQuestions =[
  {
    name: "Free Response 1",
    maxScore: 9,
    weight: 0.125,
    description: "Differential and integral calculus"
  },
  {
    name: "Free Response 2",
    maxScore: 9,
    weight: 0.125,
    description: "Series and sequences"
  },
  {
    name: "Free Response 3",
    maxScore: 9,
    weight: 0.125,
    description: "Polar and parametric functions"
  },
  {
    name: "Free Response 4",
    maxScore: 9,
    weight: 0.125,
    description: "Advanced integration techniques"
  }
];

const apCalcBCConfig = {
  title: "AP Calculus BC Score Calculator 2024 | AP Calc BC Score Calculator",
  description: "Calculate your AP Calculus BC exam score instantly with our comprehensive calculator. Updated for 2024 AP Calc BC exam format with detailed scoring guidelines for multiple choice and free response sections.",
  keywords: [
    "ap calc bc score calculator",
    "AP Calc BC calculator",
    "AP Calculus BC score calculator",
    "ap calculus score calculator",
    "ap calc calculator",
    "AP Calc BC score estimation",
    "AP Calculus BC exam calculator",
    "2024 AP Calculus BC scoring",
    "AP Calc BC score conversion",
    "AP Calculus BC FRQ scoring"
  ],
  mcqTotal: 45,
  frqQuestions,
  mcqWeight: 0.5,
  frqWeight: 0.5,
  path: "/tools/ap-calc-bc-score-calculator"
};

export function APCalcBCCalculator() {
  return <APCalculatorBase config={apCalcBCConfig} />;
}