import { APCalculatorBase } from '../../../components/tools/APCalculatorBase';


const frqQuestions = [
  {
    name: "Methods and Control Structures",
    maxScore: 9,
    weight: 0.125,
    description: "Implementation of Java methods and control flow"
  },
  {
    name: "Classes and Objects",
    maxScore: 9,
    weight: 0.125,
    description: "Object-oriented programming concepts"
  },
  {
    name: "Arrays and ArrayLists",
    maxScore: 9,
    weight: 0.125,
    description: "Array manipulation and ArrayList operations"
  },
  {
    name: "2D Arrays",
    maxScore: 9,
    weight: 0.125,
    description: "Working with 2D array data structures"
  }
];

const apCSAConfig = {
  title: "AP Computer Science A Score Calculator | AP CSA score calculator",
  description: "Calculate your AP Computer Science A exam score instantly with our comprehensive calculator. Updated for 2024 AP CSA exam format.",
  keywords: [
    "AP CSA score calculator",,
    "AP CSA calculator",
    "AP Computer Science A score calculator",
    "AP CSA score estimation",
    "2024 AP CSA scoring",
    "AP Java score calculator",
    "AP Computer Science scoring"
  ],
  mcqTotal: 40,
  frqQuestions,
  mcqWeight: 0.5,
  frqWeight: 0.5,
  path: "/tools/ap-csa-score-calculator"
};

export function APCSACalculator() {
  return <APCalculatorBase config={apCSAConfig} />;
}