import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { createRoot, hydrateRoot } from 'react-dom/client';

import './index.css';
import './styles/NewStyles.css';
import App from './App';
import HomePage from './pages/HomePage';
import ExamPage from './pages/ExamPage';
import MockTestPage from './pages/MockTestPage';
import ResultsPage from './pages/ResultsPage';
import reportWebVitals from './reportWebVitals';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import DataUploadPage from './pages/DataUploadPage';
import ComingSoon from './components/ComingSoon';
// import DmvHomePage from './pages/DMV/HomePage';
// import SscHomePage from './pages/SSC/HomePage';
// import RrbHomePage from './pages/RRB/HomePage';
// import IbpsHomePage from './pages/IBPS/HomePage';
// import SSCCGLTestSelector from './pages/SSC/SSCCGLTestSelectorPage';
// import BlogPage from './Blog/pages/BlogPage';
import BlogPostPage from './Blog/pages/BlogPost';
// import BlogCategoryPage from './Blog/pages/BlogCategoryPage';
// import BlogTagPage from './Blog/pages/BlogTagPage';
// import BlogSearchPage from './Blog/pages/BlogSearchPage';
// import BlogAuthorPage from './Blog/pages/BlogAuthorPage';
import BlogListPage from './Blog/pages/BlogList';

import ArticlesHomePage from './pages/Articles/Home';
import ArticlesCategoryPage from './pages/Articles/Category';
import ArticlesExamPage from './pages/Articles/Exam';
import ArticlesArticlePage from './pages/Articles/Article';


import { Tools } from './pages/tools/Tools';
import { SATCalculator } from './pages/tools/SATCalculator';
import { ACTCalculator } from './pages/tools/ACTCalculator';
import { APCalculator } from './pages/tools/APCalculator';
import { APUSHCalculator } from './pages/tools/ap/APUSHCalculator';
import { APLangCalculator } from './pages/tools/ap/APLangCalculator';
import { APBioCalculator } from './pages/tools/ap/APBioCalculator';
import { APChemCalculator } from './pages/tools/ap/APChemCalculator';
import { APLitCalculator } from './pages/tools/ap/APLitCalculator';
import { APGovCalculator } from './pages/tools/ap/APGovCalculator';
import { APPhysicsCalculator } from './pages/tools/ap/APPhysicsCalculator';
import { APPsychCalculator } from './pages/tools/ap/APPsychCalculator';
import { APCalcABCalculator } from './pages/tools/ap/APCalcABCalculator';
import { APCalcBCCalculator } from './pages/tools/ap/APCalcBCCalculator';
import { APWorldCalculator } from './pages/tools/ap/APWorldCalculator';
import { APCSACalculator } from './pages/tools/ap/APCSACalculator';
import { APEuroCalculator } from './pages/tools/ap/APEuroCalculator';
import { APMacroCalculator } from './pages/tools/ap/APMacroCalculator';
import { APPhysics1Calculator } from './pages/tools/ap/APPhysics1Calculator'; 
import { APPhysicsCCalculator } from './pages/tools/ap/APPhysicsCCalculator';
import { APCSPCalculator } from './pages/tools/ap/APCSPCalculator';


import About from './pages/About';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      { index: true, element: <HomePage /> },
      { path: 'exam/:examId', element: <ExamPage /> },
      { path: 'mocktest/:testId', element: <MockTestPage /> },
      { path: 'results/:testId', element: <ResultsPage /> },
      { path: 'login', element: <LoginPage /> },
      { path: 'signup', element: <SignupPage /> },
      { path: 'dataupload', element: <DataUploadPage /> },
      // { path: 'dmv', element: <DmvHomePage /> },
      { path: 'comingsoon', element: <ComingSoon /> },
      // { path: 'ssc', element: <SscHomePage /> },
      // { path: 'ssc-cgl', element: <SSCCGLTestSelector /> },
      // { path: 'ibps', element: <IbpsHomePage /> },
      // { path: 'rrb', element: <RrbHomePage /> },
      // { path: 'aptitude', element: <ComingSoon /> },
      // { path: 'reasoning', element: <ComingSoon /> },
      // { path: 'engineering', element: <ComingSoon /> },
      // { path: 'gre', element: <ComingSoon /> },
      {
        path: 'articles',
        children: [
          { path: 'region/:country', element: <ArticlesHomePage /> },
          { path: ':examId', element: <ArticlesExamPage /> },
          {path: ':examId/:categorySlug', element: < ArticlesCategoryPage /> },
          {path: ':examId/:categorySlug/:articleSlug', element: < ArticlesArticlePage /> },
          { index: true, element: <ArticlesHomePage /> }
        ]
      },
      // {path: 'articles/:examId', element: < ArticlesExamPage /> },
      // {path: 'articles/:examId/:categoryId', element: < ArticlesCategoryPage /> },
      // {path: 'articles/:examId/:categoryId/:articleSlug', element: < ArticlesArticlePage /> },

      {path: 'about', element: <About /> },
      {path: 'terms', element: <Terms /> },
      {path: 'privacy', element: <Privacy /> },
      {path: 'blog', element: <BlogListPage /> },
      {path: 'blog/:slug', element: <BlogPostPage /> },
      {
        path: 'tools',
        children: [
          { index: true, element: <Tools /> },
          { path: 'sat-score-calculator', element: <SATCalculator /> },
          { path: 'act-score-calculator', element: <ACTCalculator /> },
          { path: 'apush-score-calculator', element: <APUSHCalculator /> },
          { path: 'ap-lang-score-calculator', element: <APLangCalculator /> },
          { path: 'ap-bio-score-calculator', element: <APBioCalculator /> },
          { path: 'ap-chem-score-calculator', element: <APChemCalculator /> },
          { path: 'ap-lit-score-calculator', element: <APLitCalculator /> },
          { path: 'ap-gov-score-calculator', element: <APGovCalculator /> },
          { path: 'ap-psych-score-calculator', element: <APPsychCalculator /> },
          { path: 'ap-calc-ab-score-calculator', element: <APCalcABCalculator /> },
          { path: 'ap-calc-bc-score-calculator', element: <APCalcBCCalculator /> },
          { path: 'ap-world-score-calculator', element: <APWorldCalculator /> },
          { path: 'ap-physics-score-calculator', element: <APPhysicsCalculator /> },
          { path: 'ap-csa-score-calculator', element: <APCSACalculator /> },
          { path: 'ap-euro-score-calculator', element: <APEuroCalculator /> },
          { path: 'ap-macro-score-calculator', element: <APMacroCalculator /> },
          { path: 'ap-physics-1-score-calculator', element: <APPhysics1Calculator /> },
          { path: 'ap-physics-c-score-calculator', element: <APPhysicsCCalculator /> },
          { path: 'ap-csp-score-calculator', element: <APCSPCalculator /> }
        ]
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
