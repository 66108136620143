import { APCalculatorBase } from '../../../components/tools/APCalculatorBase';


const frqQuestions = [
  {
    name: "Experimental Design",
    maxScore: 12,
    weight: 0.125,
    description: "Design and describe an experimental investigation"
  },
  {
    name: "Quantitative/Qualitative Translation",
    maxScore: 12,
    weight: 0.125,
    description: "Translate between mathematical and verbal descriptions"
  },
  {
    name: "Paragraph Argument",
    maxScore: 7,
    weight: 0.125,
    description: "Construct scientific explanations"
  },
  {
    name: "Short Answer",
    maxScore: 7,
    weight: 0.125,
    description: "Analyze experimental data and observations"
  }
];

const apPhysics1Config = {
  title: "AP Physics 1 Score Calculator",
  description: "Calculate your AP Physics 1 exam score instantly with our comprehensive calculator. Updated for 2024 AP Physics 1 exam format with detailed scoring guidelines.",
  keywords: [
    "AP Physics 1 score calculator",
    "AP Physics 1 calculator",
    "AP Physics 1 score estimation",
    "2024 AP Physics 1 scoring",
    "AP Physics algebra calculator",
    "AP Physics 1 FRQ scoring"
  ],
  mcqTotal: 50,
  frqQuestions,
  mcqWeight: 0.5,
  frqWeight: 0.5,
  path: "/tools/ap-physics-1-score-calculator"
};

export function APPhysics1Calculator() {
  return <APCalculatorBase config={apPhysics1Config} />;
}