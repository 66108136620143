import { useState, useEffect } from 'react';
import { SectionScoring } from '../../components/tools/SectionScoring';
import { InfoSection } from '../../components/tools/InfoSection';
import { calculateSectionScore } from '../../utils/scoreCalculator';
import { motion } from 'framer-motion';
import { FAQ } from '../../components/tools/FAQ';
import { ScoringGuide } from '../../components/tools/ScoringGuide';
import { SEOHead } from '../../components/tools/SEOHead';
import Footer from '../../components/Footer';

export function SATCalculator() {
  const [sections, setSections] = useState([
    {
      name: 'Math',
      modules: [
        { moduleNumber: 1, correctAnswers: 15, totalQuestions: 22, difficulty: 'medium' },
        { moduleNumber: 2, correctAnswers: 12, totalQuestions: 22, difficulty: 'medium' }
      ],
      scaledScore: 200
    },
    {
      name: 'Reading & Writing',
      modules: [
        { moduleNumber: 1, correctAnswers: 18, totalQuestions: 27, difficulty: 'medium' },
        { moduleNumber: 2, correctAnswers: 16, totalQuestions: 27, difficulty: 'medium' }
      ],
      scaledScore: 200
    }
  ]);

  useEffect(() => {
    setSections(prevSections => 
      prevSections.map(section => ({
        ...section,
        scaledScore: calculateSectionScore(section, section.modules)
      }))
    );
  }, []);

  const updateModuleScore = (sectionIndex, moduleIndex, updatedModule) => {
    setSections(prevSections => {
      const newSections = [...prevSections];
      newSections[sectionIndex].modules[moduleIndex] = updatedModule;
      newSections[sectionIndex].scaledScore = calculateSectionScore(
        newSections[sectionIndex],
        newSections[sectionIndex].modules
      );
      return newSections;
    });
  };

  // const getDifficultyLabel = (section, moduleIndex) => {
  //   const module1Score = section.modules[0].correctAnswers;
  //   if (moduleIndex === 0) return 'Standard';
    
  //   if (section.name === 'Math') {
  //     if (module1Score >= 18) return 'Hard';
  //     if (module1Score >= 13) return 'Medium';
  //     return 'Easy';
  //   } else {
  //     if (module1Score >= 22) return 'Hard';
  //     if (module1Score >= 15) return 'Medium';
  //     return 'Easy';
  //   }
  // };

  const totalScore = sections.reduce((sum, section) => sum + section.scaledScore, 0);

  return (
    <>
    <SEOHead
      title="Digital SAT Score Calculator 2024 | Free Score Estimator"
      description="Calculate your Digital SAT score instantly with our adaptive scoring calculator. Updated for 2024 Digital SAT format with section-wise scoring for Math and Reading & Writing."
      keywords={[
        "SAT score calculator",
        "Digital SAT score calculator",
        "SAT digital score calculator",
        "2024 SAT scoring",
        "SAT Math calculator",
        "SAT Reading Writing calculator",
        "Digital SAT score estimation",
        "Free SAT calculator"
      ]}
      path="/tools/sat-score-calculator"
    />
    <div className="max-w-5xl mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center my-8"
        >
          <h1 className="text-4xl font-bold text-gray-800 mb-3">Digital SAT Score Calculator</h1>
          <p className="text-lg text-gray-600">Using Official College Board Scoring Tables</p>
        </motion.div>

        <InfoSection />

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 my-8">
          {sections.map((section, sectionIndex) => (
            <SectionScoring
              key={sectionIndex}
              section={section}
              // getDifficultyLabel={(moduleIndex) => getDifficultyLabel(section, moduleIndex)}
              onModuleChange={(moduleIndex, updatedModule) => 
                updateModuleScore(sectionIndex, moduleIndex, updatedModule)
              }
            />
          ))}
        </div>

        <motion.section 
          className="card p-8 bg-gradient-to-r from-blue-600 to-indigo-600 text-white my-8 rounded-lg shadow-xl"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <h2 className="text-3xl font-bold mb-6 text-center">Your Total Score</h2>
          <div className="flex items-center justify-center">
            <div className="text-8xl font-bold animate-pulse">
              {totalScore}
            </div>
          </div>
          <p className="text-center mt-6 text-blue-100">
            Based on College Board's Official Adaptive Scoring Tables
          </p>
        </motion.section>

        {/* <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <div className="flex space-x-4 mb-6">
            <TabButton active={activeTab === 'scores'} onClick={() => setActiveTab('scores')}>
              Score Analysis
            </TabButton>
            <TabButton active={activeTab === 'percentiles'} onClick={() => setActiveTab('percentiles')}>
              Percentiles
            </TabButton>
            <TabButton active={activeTab === 'improvements'} onClick={() => setActiveTab('improvements')}>
              Improvements
            </TabButton>
          </div>

          {activeTab === 'scores' && <ScoreAnalysis sections={sections} />}
          {activeTab === 'percentiles' && <PercentileChart totalScore={totalScore} />}
          {activeTab === 'improvements' && <Improvements sections={sections} />}
        </div> */}
        <ScoringGuide />
        <FAQ />
        <Footer />
      </div>
    </>
  );
}
