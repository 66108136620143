import { APCalculatorBase } from '../../../components/tools/APCalculatorBase';

const frqQuestions =[
  {
    name: "Concept Application",
    maxScore: 3,
    weight: 0.125,
    description: "Apply political concepts to real-world scenarios"
  },
  {
    name: "Quantitative Analysis",
    maxScore: 3,
    weight: 0.125,
    description: "Analyze and interpret quantitative political data"
  },
  {
    name: "SCOTUS Comparison",
    maxScore: 3,
    weight: 0.125,
    description: "Compare Supreme Court case decisions and implications"
  },
  {
    name: "Argument Essay",
    maxScore: 3,
    weight: 0.125,
    description: "Develop an argument about a political issue"
  }
];

const apGovConfig = {
  title: "AP Government Score Calculator 2024 | AP Gov Score Calculator",
  description: "Calculate your AP U.S. Government and Politics exam score instantly with our detailed calculator. Updated for 2024 AP Gov exam format with comprehensive scoring guidelines for multiple choice and free response sections.",
  keywords: [
    "ap gov score calculator",
    "AP Gov calculator",
    "AP Government score calculator",
    "AP Gov score estimation",
    "AP Government Politics calculator",
    "2024 AP Government scoring",
    "AP Gov score conversion",
    "AP Government FRQ scoring"
  ],
  mcqTotal: 55,
  frqQuestions,
  mcqWeight: 0.5,
  frqWeight: 0.5,
  path: "/tools/ap-gov-score-calculator"
};

export function APGovCalculator() {
  return <APCalculatorBase config={apGovConfig} />;
}