import { APCalculatorBase } from '../../../components/tools/APCalculatorBase';

const frqQuestions = [
  {
    name: "Document-Based Question (DBQ)",
    maxScore: 7,
    weight: 0.25,
    description: "Analyze historical documents and develop an argument"
  },
  {
    name: "Long Essay Question",
    maxScore: 6,
    weight: 0.15,
    description: "Develop a historical argument with evidence"
  },
  {
    name: "Short Answer Question 1",
    maxScore: 3,
    weight: 0.1,
    description: "Compare historical developments across time periods"
  },
  {
    name: "Short Answer Question 2",
    maxScore: 3,
    weight: 0.1,
    description: "Analyze historical sources and evidence"
  }
];

const apWorldConfig = {
  title: "AP World History Score Calculator 2024 | AP World Score Calculator",
  description: "Calculate your AP World History exam score instantly with our comprehensive calculator. Updated for 2024 AP World exam format with detailed scoring guidelines for multiple choice and free response sections.",
  keywords: [
    "ap world score calculator",
    "ap world history calculator",
    "AP World calculator",
    "AP World History score calculator",
    "AP World score estimation",
    "AP World History exam calculator",
    "2024 AP World History scoring",
    "AP World score conversion",
    "AP World History FRQ scoring"
  ],
  mcqTotal: 55,
  frqQuestions,
  mcqWeight: 0.4,
  frqWeight: 0.6,
  path: "/tools/ap-world-score-calculator"
};

export function APWorldCalculator() {
  return <APCalculatorBase config={apWorldConfig} />;
}