import { motion } from 'framer-motion';

export function ScoreDisclaimer() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.7 }}
      className="mt-6 p-4 bg-blue-50 border border-blue-100 rounded-lg"
    >
      <h3 className="text-sm font-semibold text-blue-800 mb-2">Score Disclaimer</h3>
      <p className="text-sm text-blue-700">
        This calculator provides an estimate based on general scoring patterns. 
        Actual scores may vary due to:
      </p>
      <ul className="mt-2 text-sm text-blue-600 list-disc list-inside">
        <li>Yearly scoring adjustments by exam boards</li>
        <li>Question difficulty variations between exam versions</li>
        <li>Score scaling and equating processes</li>
        <li>Specific grading criteria for each exam administration</li>
      </ul>
    </motion.div>
  );
}