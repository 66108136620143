import { APCalculatorBase } from '../../../components/tools/APCalculatorBase';

const frqQuestions = [
  {
    name: "Research Design FRQ",
    maxScore: 7,
    weight: 0.166,
    description: "Design and analyze psychological research"
  },
  {
    name: "Concept Application FRQ",
    maxScore: 5,
    weight: 0.166,
    description: "Apply psychological concepts to real-world scenarios"
  }
];

const apPsychConfig = {
  title: "AP Psychology Score Calculator 2024 | AP Psych Calculator",
  description: "Calculate your AP Psychology exam score instantly with our comprehensive calculator. Updated for 2024 AP Psych exam format with detailed scoring guidelines for multiple choice and free response sections.",
  keywords: [
    "AP Psych calculator",
    "ap psych score calculator",
    "AP Psychology score calculator",
    "AP Psych score estimation",
    "AP Psychology exam calculator",
    "2024 AP Psychology scoring",
    "AP Psych score conversion",
    "AP Psychology FRQ scoring"
  ],
  mcqTotal: 100,
  frqQuestions,
  mcqWeight: 0.667,
  frqWeight: 0.333,
  path: "/tools/ap-psych-score-calculator"
};

export function APPsychCalculator() {
  return <APCalculatorBase config={apPsychConfig} />;
}