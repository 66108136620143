import React from 'react';
import { Helmet } from 'react-helmet';

const Terms = () => {
  return (
    <>
    <Helmet>
      <title>Terms of Service | MockTestPro</title>
      <meta name="description" content="Read MockTestPro's terms of service and understand our policies for using our exam preparation platform." />
      <link rel="canonical" href="https://mocktestpro.com/terms" />
    </Helmet>
    <div>
      <h1>Terms of Service</h1>
      <p>Welcome to MockTestPro. These terms and conditions outline the rules and regulations for the use of MockTestPro's Website.</p>
      <h2>Introduction</h2>
      <p>By accessing this website we assume you accept these terms and conditions. Do not continue to use MockTestPro if you do not agree to take all of the terms and conditions stated on this page.</p>
      <h2>Cookies</h2>
      <p>We employ the use of cookies. By accessing MockTestPro, you agreed to use cookies in agreement with the MockTestPro's Privacy Policy.</p>
      <h2>License</h2>
      <p>Unless otherwise stated, MockTestPro and/or its licensors own the intellectual property rights for all material on MockTestPro. All intellectual property rights are reserved. You may access this from MockTestPro for your own personal use subjected to restrictions set in these terms and conditions.</p>
      <h2>Contact Us</h2>
      <p>If you have any questions about our terms, please contact us at support@mocktestpro.com.</p>
    </div>
    </>
  );
};

export default Terms;
