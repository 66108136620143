import { useState } from 'react';
import { motion } from 'framer-motion';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { SEOHead } from '../../components/tools/SEOHead';
import { ScoreDisclaimer } from '../../components/tools/ScoreDisclaimer';
import { ACTFAQ } from '../../components/tools/ACTFAQ';
import Footer from '../../components/Footer';
import 'react-circular-progressbar/dist/styles.css';

export function ACTCalculator() {
  const [sections, setSections] = useState([
    { name: 'English', score: 0, maxScore: 75 },
    { name: 'Math', score: 0, maxScore: 60 },
    { name: 'Reading', score: 0, maxScore: 40 },
    { name: 'Science', score: 0, maxScore: 40 }
  ]);

  const calculateScaledScore = (raw, max) => {
    return Math.round((raw / max) * 36);
  };

  const compositeScore = Math.round(
    sections.reduce((sum, section) => 
      sum + calculateScaledScore(section.score, section.maxScore), 0) / sections.length
  );

  const handleScoreChange = (index, value) => {
    const newSections = [...sections];
    newSections[index].score = Math.min(newSections[index].maxScore, Math.max(0, value));
    setSections(newSections);
  };

  return (
    <>
      <SEOHead
        title="ACT Score Calculator 2024 | ACT Score Estimator"
        description="Calculate your ACT score instantly with our free calculator. Updated for 2024 ACT exam format with accurate scoring guidelines for English, Math, Reading, and Science sections."
        keywords={[
          "ACT score calculator",
          "ACT calculator",
          "ACT composite score",
          "2024 ACT scoring",
          "ACT score conversion",
          "ACT score estimator",
          "Free ACT calculator"
        ]}
        path="/tools/act-score-calculator"
      />

      <div className="max-w-4xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-8"
        >
          <h1 className="text-3xl font-bold text-gray-800 mb-2">ACT Score Calculator</h1>
          <p className="text-gray-600">Calculate your ACT composite score instantly</p>
        </motion.div>

        <div className="grid gap-6 md:grid-cols-2">
          {sections.map((section, index) => (
            <motion.div
              key={section.name}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-white p-6 rounded-lg shadow-md"
            >
              <h2 className="text-xl font-semibold mb-4">{section.name}</h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm text-gray-600 mb-2">
                    Raw Score
                  </label>
                  <div className="flex gap-4">
                    <input
                      type="range"
                      min="0"
                      max={section.maxScore}
                      value={section.score}
                      onChange={(e) => handleScoreChange(index, parseInt(e.target.value))}
                      className="flex-1"
                    />
                    <input
                      type="number"
                      min="0"
                      max={section.maxScore}
                      value={section.score}
                      onChange={(e) => handleScoreChange(index, parseInt(e.target.value) || 0)}
                      className="w-20 px-3 py-1 border rounded-md text-center"
                    />
                  </div>
                </div>
                <div className="text-right text-sm text-gray-600">
                  Scaled Score: {calculateScaledScore(section.score, section.maxScore)}
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="mt-8 bg-gradient-to-r from-blue-600 to-indigo-600 p-8 rounded-lg text-white text-center"
        >
          <h2 className="text-2xl font-bold mb-4">Composite Score</h2>
          <div className="w-32 h-32 mx-auto">
            <CircularProgressbar
              value={(compositeScore / 36) * 100}
              text={compositeScore.toString()}
              styles={buildStyles({
                textSize: '32px',
                pathColor: `rgba(255, 255, 255, ${compositeScore / 36})`,
                textColor: '#ffffff',
                trailColor: 'rgba(255, 255, 255, 0.2)',
              })}
            />
          </div>
        </motion.div>

        <ScoreDisclaimer />

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.6 }}
          className="mt-8 bg-white p-6 rounded-lg shadow-md"
        >
          <h2 className="text-xl font-semibold mb-4">Score Guide</h2>
          <div className="grid gap-4">
            <div className="p-4 bg-gray-50 rounded-lg">
              <h3 className="font-semibold text-lg mb-2">Understanding Your Score</h3>
              <p className="text-gray-600">
                The ACT Composite Score is the average of your four section scores (English, Math, Reading, and Science), 
                rounded to the nearest whole number. Scores range from 1-36, with 36 being the highest possible score.
              </p>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              {[
                { range: '33-36', level: 'Exceptional' },
                { range: '29-32', level: 'Excellent' },
                { range: '24-28', level: 'Above Average' },
                { range: '20-23', level: 'Average' }
              ].map((score) => (
                <div key={score.range} className="text-center p-3 bg-gray-50 rounded-lg">
                  <div className="text-xl font-bold text-blue-600">{score.range}</div>
                  <div className="text-sm text-gray-600">{score.level}</div>
                </div>
              ))}
            </div>
          </div>
        </motion.div>

        <ACTFAQ />
        <Footer />
      </div>
    </>
  );
}
