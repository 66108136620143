import { ModuleInput } from './ModuleInput';
import { motion } from 'framer-motion';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export function SectionScoring({ section, onModuleChange }) {
  const percentage = ((section.scaledScore - 200) / 600) * 100;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white p-6 rounded-lg shadow-md mb-6"
    >
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold text-gray-800">
          {section.name} Section
        </h2>
        <div className="w-24 h-24">
          <CircularProgressbar
            value={percentage}
            text={`${section.scaledScore}`}
            styles={buildStyles({
              textSize: '24px',
              pathColor: `rgba(37, 99, 235, ${percentage / 100})`,
              textColor: '#1e40af',
              trailColor: '#ddd',
            })}
          />
        </div>
      </div>
      
      <div className="space-y-4">
        {section.modules.map((module, index) => (
          <ModuleInput
            key={index}
            module={module}
            sectionName={section.name}
            onChange={(updatedModule) => onModuleChange(index, updatedModule)}
          />
        ))}
      </div>

      <div className="mt-6 p-4 bg-gradient-to-r from-blue-50 to-indigo-50 rounded-md">
        <div className="text-lg font-semibold text-blue-800">
          Section Score: {section.scaledScore}
        </div>
        <div className="text-sm text-blue-600 mt-1">
          Based on adaptive scoring across modules
        </div>
      </div>
    </motion.div>
  );
}
