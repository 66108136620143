export const MATH_CONVERSION = {
    MODULE1_THRESHOLDS: {
      HIGH: 18,
      MEDIUM: 13,
      LOW: 0
    },
    SCORE_TABLES: {
      HARD_PATH: {
          44: 800, 43: 790, 42: 780, 41: 760, 40: 740, 39: 720, 38: 700, 37: 680,
          36: 660, 35: 640, 34: 620, 33: 600, 32: 580, 31: 560, 30: 540, 29: 520,
          28: 500, 27: 480, 26: 460, 25: 440, 24: 420, 23: 400, 22: 380, 21: 360,
          20: 340, 19: 320, 18: 300, 17: 280, 16: 260, 15: 240, 14: 220, 13: 210,
          12: 208, 11: 206, 10: 205, 9: 204, 8: 203, 7: 202, 6: 201, 5: 201, 4: 200,
          3: 200, 2: 200, 1: 200, 0: 200
      },
      MEDIUM_PATH: {
          44: 760, 43: 740, 42: 720, 41: 700, 40: 680, 39: 660, 38: 640, 37: 620,
          36: 600, 35: 580, 34: 560, 33: 540, 32: 520, 31: 500, 30: 480, 29: 460,
          28: 440, 27: 420, 26: 400, 25: 380, 24: 360, 23: 340, 22: 320, 21: 300,
          20: 280, 19: 260, 18: 240, 17: 220, 16: 210, 15: 208, 14: 206, 13: 204,
          12: 203, 11: 202, 10: 201, 9: 201, 8: 200, 7: 200, 6: 200, 5: 200, 4: 200,
          3: 200, 2: 200, 1: 200, 0: 200
      },
      EASY_PATH: {
          44: 720, 43: 700, 42: 680, 41: 660, 40: 640, 39: 620, 38: 600, 37: 580,
          36: 560, 35: 540, 34: 520, 33: 500, 32: 480, 31: 460, 30: 440, 29: 420,
          28: 400, 27: 380, 26: 360, 25: 340, 24: 320, 23: 300, 22: 280, 21: 260,
          20: 240, 19: 220, 18: 210, 17: 208, 16: 206, 15: 204, 14: 203, 13: 202,
          12: 201, 11: 201, 10: 200, 9: 200, 8: 200, 7: 200, 6: 200, 5: 200, 4: 200,
          3: 200, 2: 200, 1: 200, 0: 200
      }
  }
  };
  
  export const READING_WRITING_CONVERSION = {
    MODULE1_THRESHOLDS: {
      HIGH: 22,
      MEDIUM: 15,
      LOW: 0
    },
    SCORE_TABLES: {
      HARD_PATH: {
          54: 800, 53: 780, 52: 760, 51: 740, 50: 720, 49: 700, 48: 680, 47: 660,
          46: 640, 45: 620, 44: 600, 43: 580, 42: 560, 41: 540, 40: 520, 39: 500,
          38: 480, 37: 460, 36: 440, 35: 420, 34: 400, 33: 380, 32: 360, 31: 340,
          30: 320, 29: 300, 28: 280, 27: 260, 26: 240, 25: 220, 24: 210, 23: 208,
          22: 206, 21: 204, 20: 203, 19: 202, 18: 201, 17: 201, 16: 200, 15: 200,
          14: 200, 13: 200, 12: 200, 11: 200, 10: 200, 9: 200, 8: 200, 7: 200,
          6: 200, 5: 200, 4: 200, 3: 200, 2: 200, 1: 200, 0: 200
      },
      MEDIUM_PATH: {
          54: 760, 53: 740, 52: 720, 51: 700, 50: 680, 49: 660, 48: 640, 47: 620,
          46: 600, 45: 580, 44: 560, 43: 540, 42: 520, 41: 500, 40: 480, 39: 460,
          38: 440, 37: 420, 36: 400, 35: 380, 34: 360, 33: 340, 32: 320, 31: 300,
          30: 280, 29: 260, 28: 240, 27: 220, 26: 210, 25: 208, 24: 206, 23: 204,
          22: 203, 21: 202, 20: 201, 19: 201, 18: 200, 17: 200, 16: 200, 15: 200,
          14: 200, 13: 200, 12: 200, 11: 200, 10: 200, 9: 200, 8: 200, 7: 200,
          6: 200, 5: 200, 4: 200, 3: 200, 2: 200, 1: 200, 0: 200
      },
      EASY_PATH: {
          54: 720, 53: 700, 52: 680, 51: 660, 50: 640, 49: 620, 48: 600, 47: 580,
          46: 560, 45: 540, 44: 520, 43: 500, 42: 480, 41: 460, 40: 440, 39: 420,
          38: 400, 37: 380, 36: 360, 35: 340, 34: 320, 33: 300, 32: 280, 31: 260,
          30: 240, 29: 220, 28: 210, 27: 208, 26: 206, 25: 204, 24: 203, 23: 202,
          22: 201, 21: 201, 20: 200, 19: 200, 18: 200, 17: 200, 16: 200, 15: 200,
          14: 200, 13: 200, 12: 200, 11: 200, 10: 200, 9: 200, 8: 200, 7: 200,
          6: 200, 5: 200, 4: 200, 3: 200, 2: 200, 1: 200, 0: 200
      }
  }
  };
  