import { APCalculatorBase } from '../../../components/tools/APCalculatorBase';

const frqQuestions= [
  {
    name: "Long Free Response 1",
    maxScore: 10,
    weight: 0.166,
    description: "Economic analysis and policy implications"
  },
  {
    name: "Long Free Response 2",
    maxScore: 10,
    weight: 0.166,
    description: "Graph-based economic analysis"
  },
  {
    name: "Short Free Response",
    maxScore: 5,
    weight: 0.166,
    description: "Economic concepts and calculations"
  }
];

const apMacroConfig = {
  title: "AP Macroeconomics Score Calculator | AP Macro score calculator",
  description: "Calculate your AP Macroeconomics exam score instantly with our comprehensive calculator. Updated for 2024 AP Macro exam format.",
  keywords: [
    "AP Macro score calculator",
    "AP Macro calculator",
    "AP Macroeconomics score calculator",
    "AP Macro score estimation",
    "2024 AP Macro scoring",
    "AP Economics calculator"
  ],
  mcqTotal: 60,
  frqQuestions,
  mcqWeight: 0.667,
  frqWeight: 0.333,
  path: "/tools/ap-macro-score-calculator"
};

export function APMacroCalculator() {
  return <APCalculatorBase config={apMacroConfig} />;
}