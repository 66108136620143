// services/api.js
import axios from 'axios';
import crypto from 'crypto-js';

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const API_SECRET = process.env.REACT_APP_API_SECRET;

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-API-Key': API_KEY,
  },
});

api.interceptors.request.use((config) => {
  const timestamp = Date.now().toString();
  const method = config.method.toUpperCase();
  const path = config.url.split('?')[0]; // Remove query parameters if any
  const signature = crypto.HmacSHA256(`${method}${path}${timestamp}`, API_SECRET).toString();

  config.headers['X-Timestamp'] = timestamp;
  config.headers['X-Signature'] = signature;

  return config;
});

export const login = (credentials) => api.post('/api/auth/login', credentials);
export const signup = (userData) => api.post('/api/auth/signup', userData);
export const getExam = (examId) => api.get(`/api/exams/exam/${examId}`);
export const getExams = () => api.get('/api/exams/allexams');
export const getTopExams = () => api.get('/api/exams/topexams');
export const getMockTests = (examId) => api.get(`/api/mocktests/${examId}`);
export const getMockTestQuestions = (testId) => api.get(`/api/mocktests/${testId}/questions`);
export const submitMockTest = (testId, answers) => api.post(`/api/mocktests/${testId}/submit`, { answers });
export const postSearchKeyword = (keyword) => api.post('/api/exams/search', keyword);
export const postUploadData = (data) => api.post('/api/upload', data, {
  headers: {
    ...api.defaults.headers,
    'Content-Type':'multipart/form-data',
  },
});

export const getBlogPosts = () => api.get('/api/articles/posts');
export const getBlogPost = (id) => api.get(`/api/articles/posts/${id}`);
export const getBlogPostBySlug = (slug) =>  api.get(`/api/articles/posts/${slug}`);

// // Fetch all exams for Home.js
// export const getExamsForArticles = async () => {
//   const response = await fetch(`${API_URL}/api/articles/exams`);
//   return response.json();
// };

// // Fetch categories for specific exam - ExamPage.js
// export const getExamCategoriesForArticles = async (examId) => {
//   const response = await fetch(`${API_URL}/api/articles/exams/${examId}/categories`);
//   return response.json();
// };

// // Fetch category details and articles - CategoryPage.js
// export const getCategoryDetailsForArticles = async (categoryId) => {
//   const response = await fetch(`${API_URL}/api/articles/categories/${categoryId}`);
//   return response.json();
// };

// export const getCategoryArticles = async (categoryId) => {
//   const response = await fetch(`${API_URL}/api/articles/categories/${categoryId}/articles`);
//   return response.json();
// };

// // Fetch article details - ArticlePage.js
// export const getArticleBySlug = async (categoryId, articleSlug) => {
//   const response = await fetch(`${API_URL}/api/articles/categories/${categoryId}/${articleSlug}`);
//   return response.json();
// };

// Fetch all exams for Home.js
export const getExamsForArticles = () => 
  api.get('/api/articles/exams');

// Fetch categories for specific exam - ExamPage.js
export const getExamCategoriesForArticles = (examId) => 
  api.get(`/api/articles/exams/${examId}/categories`);

// Fetch category details and articles - CategoryPage.js
export const getCategoryDetailsForArticles = (categoryId) => 
  api.get(`/api/articles/categories/${categoryId}`);

export const getCategoryArticles = (categoryId) => 
  api.get(`/api/articles/categories/${categoryId}/articles`);

// Fetch article details - ArticlePage.js
export const getArticleBySlug = (examId, categoryId, articleSlug) => 
  api.get(`/api/articles/categories/${examId}/${categoryId}/${articleSlug}`);


