import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

export function ModuleInput({ module, onChange, sectionName }) {
  return (
    <div className="bg-gradient-to-r from-white to-blue-50 p-4 rounded-lg shadow-sm mb-4">
      <h3 className="font-medium text-gray-700 mb-4">
        {sectionName} Module {module.moduleNumber}
      </h3>
      <div className="space-y-4">
        <div>
          <label className="block text-sm text-gray-600 mb-2">
            Correct Answers
          </label>
          <div className="flex items-center gap-4">
            <div className="flex-1">
              <Slider
                min={0}
                max={module.totalQuestions}
                value={module.correctAnswers}
                onChange={(value) => onChange({
                  ...module,
                  correctAnswers: typeof value === 'number' ? value : 0
                })}
                trackStyle={{ backgroundColor: '#2563eb' }}
                handleStyle={{
                  borderColor: '#2563eb',
                  backgroundColor: '#2563eb'
                }}
                railStyle={{ backgroundColor: '#e2e8f0' }}
              />
            </div>
            <input
              type="number"
              min={0}
              max={module.totalQuestions}
              value={module.correctAnswers}
              onChange={(e) => {
                const value = Math.min(module.totalQuestions, Math.max(0, parseInt(e.target.value) || 0));
                onChange({
                  ...module,
                  correctAnswers: value
                });
              }}
              className="w-20 px-3 py-1 border rounded-md text-center"
            />
          </div>
        </div>
        
        {/* <div>
          <label className="block text-sm text-gray-600 mb-2">
            Module Difficulty
          </label>
          <div className="flex gap-2">
            {['easy', 'medium', 'hard'].map((diff) => (
              <button
                key={diff}
                onClick={() => onChange({
                  ...module,
                  difficulty: diff
                })}
                className={`flex-1 py-2 px-3 rounded-md text-sm font-medium transition-colors
                  ${module.difficulty === diff 
                    ? 'bg-blue-600 text-white' 
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'}`}
              >
                {diff.charAt(0).toUpperCase() + diff.slice(1)}
              </button>
            ))}
          </div>
        </div> */}
      </div>
    </div>
  );
}
