import { APCalculatorBase } from '../../../components/tools/APCalculatorBase';

const frqQuestions = [
  {
    name: "Synthesis Essay",
    maxScore: 6,
    weight: 0.183,
    description: "Develop an argument using provided sources"
  },
  {
    name: "Rhetorical Analysis Essay",
    maxScore: 6,
    weight: 0.183,
    description: "Analyze author's rhetorical strategies"
  },
  {
    name: "Argument Essay",
    maxScore: 6,
    weight: 0.183,
    description: "Develop an argument on a given topic"
  }
];

const apLangConfig = {
  title: "AP Language Score Calculator 2024 | AP Lang Score Calculator",
  description: "Calculate your AP English Language and Composition exam score instantly with our detailed calculator. Updated for 2024 AP Lang exam format with comprehensive scoring guidelines for multiple choice and essays.",
  keywords: [
    "ap lang score calculator",
    "AP Lang calculator",
    "ap english language score calculator",
    "ap language calculator",
    "AP Language score calculator",
    "ap language and composition score calculator",
    "AP Lang score estimation",
    "AP English Language calculator",
    "2024 AP Language scoring",
    "AP Lang score conversion",
    "AP Language essay scoring"
  ],
  mcqTotal: 45,
  frqQuestions,
  mcqWeight: 0.45,
  frqWeight: 0.55,
  path: "/tools/ap-lang-score-calculator"
};

export function APLangCalculator() {
  return <APCalculatorBase config={apLangConfig} />;
}