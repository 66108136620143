import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getExam, getMockTests } from '../services/api';
import {
  Body, Heading, SubTitle, InfoBox, TestGrid, TestLink, QuestionCount, Section, ResourceLink, Loader
} from '../styles/ExamStyledComponents';
import { FaBook, FaClock, FaClipboardCheck, FaClipboardList, FaLink, FaInfoCircle } from 'react-icons/fa';
import Footer from '../components/Footer';
import Breadcrumb from '../components/BreadCrumb';
import FAQSection from '../components/FAQSection';
import ResourceSection from '../components/ResourceSection';
// import TestimonialSection from '../components/TestimonialSection';

const getPreferredDomain = (examType) => {
  const subdomainMapping = {
    dmv: 'dmv.mocktestpro.com',
    ssc: 'ssc.mocktestpro.com',
    ibps: 'ibps.mocktestpro.com',
    rrb: 'rrb.mocktestpro.com',
  // Add more mappings as needed
  };
  return subdomainMapping[examType] || 'mocktestpro.com';
};

const formatExamId = (examId) => {
  return examId.toLowerCase().replace(/_/g, '-');
};

const ExamPage = () => {
  const { examId } = useParams();
  const [exam, setExam] = useState(null);
  const [mockTests, setMockTests] = useState([]);
  const [loading, setLoading] = useState(true);

  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      fetchExamAndMockTests();
    }
  }, [examId]);

  const fetchExamAndMockTests = async () => {
    try {
      setLoading(true);
      const [examResponse, mockTestsResponse] = await Promise.all([getExam(examId), getMockTests(examId)]);
      setExam(examResponse.data);
      setMockTests(mockTestsResponse.data);
    } catch (error) {
      console.error('Error fetching exam data:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loader />;
  if (!exam) return <Body>Exam not found</Body>;

  const preferredDomain = getPreferredDomain(exam.type);

  const formattedExamId = formatExamId(examId);

  return (
    <Body>
      <Helmet>
        <title>{`${exam.title} | MockTestPro`}</title>
        <meta name="description" content={`Prepare for ${exam.title} with our comprehensive mock tests. ${exam.description}`} />
        <meta name="keywords" content={`${exam.keywords}, mock test, exam preparation`} />
        <link rel="canonical" href={`https://${preferredDomain}/exam/${formattedExamId}`} />
        <meta property="og:title" content={`${exam.title} | MockTestPro`} />
        <meta property="og:description" content={`Prepare for ${exam.title} with our comprehensive mock tests. ${exam.description}`} />
        <meta property="og:url" content={`https://${preferredDomain}/exam/${formattedExamId}`} />
        <meta property="og:site_name" content="MockTestPro" />
        <meta property="og:image" content={`https://${preferredDomain}/images/exams/${formattedExamId}.jpg`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${exam.title} | MockTestPro`} />
        <meta name="twitter:description" content={`Prepare for ${exam.title} with our comprehensive mock tests. ${exam.description}`} />
        <meta name="twitter:image" content={`https://${preferredDomain}/images/exams/${formattedExamId}.jpg`} />
      </Helmet>

      <Breadcrumb items={[
        { label: 'Home', url: '/' },
        { label: exam.title, url: `/exam/${formattedExamId}` }
      ]} />

      <Heading><h1>{exam.title}</h1></Heading>

      <Section>
        <SubTitle>
          <FaInfoCircle aria-hidden="true" style={{ marginRight: '0.5em' }} /> About the {exam.title}
        </SubTitle>
        <InfoBox>
          <strong><FaBook aria-hidden="true" />  Key Information:</strong>
          <ul>
            {exam.keyInformation.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          <p>Preparing with our mock tests can significantly increase your chances of passing on your first attempt!</p>
        </InfoBox>
      </Section>

<Section>
      <SubTitle>
        <FaClipboardList aria-hidden="true" style={{ marginRight: '0.5em' }} /> Practice with Mock Tests
      </SubTitle>
      <p>Choose from {mockTests.length} available tests to challenge your knowledge and prepare for the exam!</p>

      <TestGrid>
        {mockTests.map(test => (
          <TestLink key={test.testId} to={`/mocktest/${test.testId}`} state={{ testData: test }}>
            <div className="test-title">{test.title || `Mock Test ${test.testId}`}</div>
            <QuestionCount>Questions: {test.totalQuestions}</QuestionCount>
            <QuestionCount>Total Marks: {test.totalMarks}</QuestionCount>
          </TestLink>
        ))}
      </TestGrid>
      </Section>
      <Section>
        <SubTitle>
          <FaInfoCircle aria-hidden="true" style={{ marginRight: '0.5em' }} /> Did You Know?
        </SubTitle>
        <InfoBox>
          <ul>
            {exam.didYouKnow.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </InfoBox>
      </Section>

      <Section><FAQSection faqs={exam.faqs} /></Section>
      <Section><ResourceSection resources={exam.resources} /></Section>

      <Footer />

      <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Course",
            "name": exam.title,
            "description": exam.description,
            "courseWorkload": `PT${exam.totalQuestions * 1}M`,
            "provider": {
              "@type": "Organization",
              "name": "MockTestPro",
              "sameAs": `https://${preferredDomain}`
            },
            "hasCourseInstance": mockTests.map(test => ({
              "@type": "CourseInstance",
              "name": test.title || `Mock Test ${test.testId}`,
              "courseMode": "online",
              "courseSchedule": {
                "@type": "Schedule",
                "duration": "PT60M"
              },
              "offers": {
                "@type": "Offer",
                "price": "0",
                "priceCurrency": "USD",
                "availability": "http://schema.org/InStock"
              }
            }))
          })}
        </script>
    </Body>
  );
};

export default ExamPage;