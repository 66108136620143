import { useState } from 'react';
import { motion } from 'framer-motion';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { SEOHead } from './SEOHead';
import { ScoreDisclaimer } from './ScoreDisclaimer';
import { APExamFAQ } from './APExamFAQ';
import { DetailedFreeResponse } from './DetailedFreeResponse';
import 'react-circular-progressbar/dist/styles.css';
import Footer from '../Footer';

export function APCalculatorBase({ config }) {
  const [mcqScore, setMcqScore] = useState(0);
  const [frqScores, setFrqScores] = useState(
    config.frqQuestions.map(() => 0)
  );

  const calculateAPScore = () => {
    const mcqPercentage = (mcqScore / config.mcqTotal) * config.mcqWeight;
    
    const frqPercentage = frqScores.reduce((sum, score, index) => {
      const question = config.frqQuestions[index];
      return sum + (score / question.maxScore) * question.weight;
    }, 0);

    const totalPercentage = (mcqPercentage + frqPercentage) * 100;

    if (totalPercentage >= 85) return 5;
    if (totalPercentage >= 70) return 4;
    if (totalPercentage >= 50) return 3;
    if (totalPercentage >= 35) return 2;
    return 1;
  };

  const updateFRQScore = (index, value) => {
    const newScores = [...frqScores];
    newScores[index] = value;
    setFrqScores(newScores);
  };

  const score = calculateAPScore();
  const percentage = ((score - 1) / 4) * 100;

  return (
    <>
      <SEOHead
        title={config.title}
        description={config.description}
        keywords={config.keywords}
        path={config.path}
      />
      
      <div className="max-w-4xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-8"
        >
          <h1 className="text-3xl font-bold text-gray-800 mb-2">{config.title}</h1>
          <p className="text-gray-600">{config.description}</p>
        </motion.div>

        <div className="grid gap-6">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            className="bg-white p-6 rounded-lg shadow-md"
          >
            <h2 className="text-xl font-semibold mb-4">Multiple Choice Section</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm text-gray-600 mb-2">
                  Correct Answers (0-{config.mcqTotal})
                </label>
                <div className="flex gap-4">
                  <input
                    type="range"
                    min="0"
                    max={config.mcqTotal}
                    value={mcqScore}
                    onChange={(e) => setMcqScore(parseInt(e.target.value))}
                    className="flex-1"
                  />
                  <input
                    type="number"
                    min="0"
                    max={config.mcqTotal}
                    value={mcqScore}
                    onChange={(e) => setMcqScore(Math.min(config.mcqTotal, Math.max(0, parseInt(e.target.value) || 0)))}
                    className="w-20 px-3 py-1 border rounded-md text-center"
                  />
                </div>
                <div className="mt-2 text-xs text-gray-500">
                  Weight: {(config.mcqWeight * 100).toFixed(1)}% of total score
                </div>
              </div>
            </div>
          </motion.div>

          <DetailedFreeResponse
            questions={config.frqQuestions}
            scores={frqScores}
            onScoreChange={updateFRQScore}
          />
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="mt-8 bg-gradient-to-r from-blue-600 to-indigo-600 p-8 rounded-lg text-white text-center"
        >
          <h2 className="text-2xl font-bold mb-4">Estimated AP Score</h2>
          <div className="w-32 h-32 mx-auto">
            <CircularProgressbar
              value={percentage}
              text={score.toString()}
              styles={buildStyles({
                textSize: '32px',
                pathColor: `rgba(255, 255, 255, ${percentage / 100})`,
                textColor: '#ffffff',
                trailColor: 'rgba(255, 255, 255, 0.2)',
              })}
            />
          </div>
          <p className="mt-4 text-blue-100">
            Based on College Board scoring guidelines
          </p>
        </motion.div>

        <ScoreDisclaimer />

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.6 }}
          className="mt-8 bg-white p-6 rounded-lg shadow-md"
        >
          <h2 className="text-xl font-semibold mb-4">Score Guide</h2>
          <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
            {[5, 4, 3, 2, 1].map((level) => (
              <div key={level} className="text-center p-3 bg-gray-50 rounded-lg">
                <div className="text-2xl font-bold text-blue-600">{level}</div>
                <div className="text-sm text-gray-600">
                  {level === 5 ? 'Extremely well qualified' :
                   level === 4 ? 'Well qualified' :
                   level === 3 ? 'Qualified' :
                   level === 2 ? 'Possibly qualified' :
                   'No recommendation'}
                </div>
              </div>
            ))}
          </div>
        </motion.div>

        <APExamFAQ examName={config.title.split(" Score")[0]} />
        <Footer />
      </div>
    </>
  );
}
