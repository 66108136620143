import React, { useState, useEffect, useContext  } from 'react';
import { Link } from 'react-router-dom';
import { BookOpen, Users, Trophy, Brain, FileText, GraduationCap, Briefcase, PenTool, Calculator, Pencil, Book } from 'lucide-react';
import { Helmet } from 'react-helmet';
import { LocationContext } from '../../App';
import { getExamsForArticles } from '../../services/api';
import Footer from '../../components/Footer';

// Icon mapping object to map backend icon names to Lucide components
const iconMapping = {
  'calculator': Calculator,
  'pencil': Pencil,
  'book': Book,
  'users': Users,
  'trophy': Trophy,
  'brain': Brain,
  'book': BookOpen,
  'graduation': GraduationCap,
  'pen': PenTool
};

const examsByLocation = {
  US: [
    {
      slug: "sat",
      title: "SAT",
      description: "Scholastic Assessment Test",
      icon: "calculator",
      color: "bg-blue-500",
      topics: 12,
      articles: 45
    },
    {
      slug: "act",
      title: "ACT",
      description: "American College Testing",
      icon: "pencil",
      color: "bg-green-500",
      topics: 10,
      articles: 38
    },
    {
      slug: "ap",
      title: "AP",
      description: "Advanced Placement",
      icon: "book",
      color: "bg-purple-500",
      topics: 20,
      articles: 60
    }
  ],
  IN: [
    {
      slug: 'ssc',
      title: 'SSC',
      description: 'Staff Selection Commission Examinations',
      icon: "users",
      color: 'bg-blue-500',
      topics: 12,
      articles: 45
    },
    {
      slug: 'ibps',
      title: 'IBPS RRB',
      description: 'Institute of Banking Personnel Selection',
      icon: "trophy",
      color: 'bg-green-500',
      topics: 15,
      articles: 52
    },
    {
      slug: 'cat',
      title: 'CAT',
      description: 'Common Admission Test',
      icon: "brain",
      color: 'bg-purple-500',
      topics: 10,
      articles: 38
    },
    {
      slug: 'upsc',
      title: 'UPSC',
      description: 'Union Public Service Commission',
      icon: "graduation",
      color: 'bg-indigo-500',
      topics: 20,
      articles: 85
    },
    {
      slug: 'gate',
      title: 'GATE',
      description: 'Graduate Aptitude Test in Engineering',
      icon: "pen",
      color: 'bg-amber-500',
      topics: 14,
      articles: 56
    },
    {
      slug: "gre",
      title: "GRE",
      description: "Graduate Record Examination",
      icon: "calculator",
      color: "bg-teal-500",
      topics: 14,
      articles: 48
    },
    {
      slug: "gmat",
      title: "GMAT",
      description: "Graduate Management Admission Test",
      icon: "brain",
      color: "bg-orange-500",
      topics: 12,
      articles: 42
    },
    {
      slug: "cds",
      title: "CDS",
      description: "Combined Defence Services",
      icon: "trophy",
      color: "bg-red-500",
      topics: 10,
      articles: 35
    },
    {
      slug: "nda",
      title: "NDA",
      description: "National Defence Academy",
      icon: "graduation",
      color: "bg-indigo-500",
      topics: 11,
      articles: 40
    },
    {
      slug: "rrb",
      title: "RRB",
      description: "Railway Recruitment Board",
      icon: "users",
      color: "bg-cyan-500",
      topics: 15,
      articles: 55
    },
    {
      slug: "aptitude",
      title: "Aptitude",
      description: "Quantitative Aptitude",
      icon: "calculator",
      color: "bg-emerald-500",
      topics: 16,
      articles: 60
    },
    {
      slug: "reasoning",
      title: "Reasoning",
      description: "Logical Reasoning",
      icon: "brain",
      color: "bg-violet-500",
      topics: 13,
      articles: 45
    },
    {
      slug: "english",
      title: "English",
      description: "English Language Skills",
      icon: "pen",
      color: "bg-rose-500",
      topics: 12,
      articles: 50
    },
    {
      slug: 'afcat',
      title: 'AFCAT',
      description: 'Air Force Common Admission Test',
      icon: "brain",
      color: 'bg-sky-500',
      topics: 12,
      articles: 45
    }
  ]
};

export default function Home() {
  const {userLocation} = useContext(LocationContext);
  const currentExams = examsByLocation[userLocation] || examsByLocation['US'];
  // const [exams, setExams] = useState([]);

  // useEffect(() => {
  //   const fetchExams = async () => {
  //     const response = await getExamsForArticles();
  //     setExams(response.data.data);
  //   };
  //   fetchExams();
  // }, []);
  
  useEffect(() => {
    // Set canonical URL
    const canonical = document.createElement('link');
    canonical.rel = 'canonical';
    canonical.href = `https://mocktestpro.com/articles/region/${userLocation.toLowerCase()}`;
    document.head.appendChild(canonical);

    // Set alternate URLs for different regions
    const alternates = [
      { rel: 'alternate', hreflang: 'en-us', href: 'https://mocktestpro.com/articles/region/us' },
      { rel: 'alternate', hreflang: 'en-in', href: 'https://mocktestpro.com/articles/region/in' }
    ];

    alternates.forEach(alt => {
      const link = document.createElement('link');
      Object.entries(alt).forEach(([key, value]) => link[key] = value);
      document.head.appendChild(link);
    });

    return () => {
      document.head.removeChild(canonical);
      alternates.forEach(() => document.head.removeChild(document.querySelector(`link[hreflang]`)));
    };
  }, [userLocation]);

  return (
<>
    <Helmet>
      <title>Competitive Exam Preparation Platform | MockTestPro</title>
      <meta name="description" content="Comprehensive study materials, practice tests, and expert guidance for top competitive examinations. Start your exam preparation journey today." />
      <meta name="keywords" content="competitive exams, exam preparation, mock tests, study materials" />
      <link rel="canonical" href={`https://mocktestpro.com/articles/region/${userLocation.toLowerCase()}`} />
    </Helmet>
    <div className="bg-gray-50 min-h-screen">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-16">
          <h1 className="text-4xl sm:text-5xl font-bold text-gray-900 mb-4">
            Your Gateway to Competitive Exam Success
          </h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Comprehensive study materials, practice tests, and expert guidance for India's top competitive examinations
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 xl:gap-8">
          {currentExams.map((exam) => {
            const Icon = iconMapping[exam.icon] || BookOpen;
            return (
              <Link
                key={exam.id}
                to={`${exam.slug}`}
                className="group transform hover:scale-105 transition-all duration-200"
              >
                <div className="bg-white rounded-xl shadow-md overflow-hidden h-full">
                  <div className={`${exam.color || 'bg-blue-500'} p-6 group-hover:opacity-90 transition-opacity`}>
                    <Icon className="h-12 w-12 text-white" />
                  </div>
                  <div className="p-6">
                    <h3 className="text-xl font-semibold text-gray-900 mb-2 group-hover:text-indigo-600 transition-colors">
                      {exam.title}
                    </h3>
                    <p className="text-gray-600 mb-4">{exam.description}</p>
                    <div className="flex items-center space-x-4 text-sm text-gray-500">
                      <div className="flex items-center">
                        <BookOpen className="h-4 w-4 mr-1" />
                        {exam.topics || 0} Topics
                      </div>
                      <div className="flex items-center">
                        <FileText className="h-4 w-4 mr-1" />
                        {exam.articles || 0} Articles
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>

        <div className="mt-16 bg-white rounded-xl shadow-lg p-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-6">
            Why Choose MokTestPro?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="flex flex-col items-center text-center">
              <div className="bg-indigo-100 p-4 rounded-full mb-4">
                <BookOpen className="h-8 w-8 text-indigo-600" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Comprehensive Content</h3>
              <p className="text-gray-600">
                Detailed study materials covering all exam topics
              </p>
            </div>
            <div className="flex flex-col items-center text-center">
              <div className="bg-green-100 p-4 rounded-full mb-4">
                <Users className="h-8 w-8 text-green-600" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Expert Guidance</h3>
              <p className="text-gray-600">
                Learn from experienced educators and toppers
              </p>
            </div>
            <div className="flex flex-col items-center text-center">
              <div className="bg-purple-100 p-4 rounded-full mb-4">
                <Trophy className="h-8 w-8 text-purple-600" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Regular Updates</h3>
              <p className="text-gray-600">
                Stay current with the latest exam patterns
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
    </>
  );
}
